import { RouteObject } from 'react-router-dom';
import AddUpdate from '../../pages/settings/department/AddUpdate';
import List from '../../pages/settings/department/List';
import AssignDivision from '../../pages/settings/department/AssignDivision';
import AssignMedium from '../../pages/settings/department/AssignMedium';

export const DEPARTMENT_TITLE = 'Department';
export const DEPARTMENT_LIST = '/department';
export const DEPARTMENT_CREATE = '/department/add';
export const DEPARTMENT_UPDATE = '/department/:id/edit';
export const DEPARTMENT_DIVISION = '/department/:id/division-assgin';
export const DEPARTMENT_MEDIUM = '/department/:id/medium-assgin';

const DepartmentRoutes: RouteObject[] = [
  {
    path: DEPARTMENT_LIST,
    element: <List />,
  },
  {
    path: DEPARTMENT_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: DEPARTMENT_DIVISION,
    element: <AssignDivision />,
  },
  {
    path: DEPARTMENT_MEDIUM,
    element: <AssignMedium />,
  },
  {
    path: DEPARTMENT_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default DepartmentRoutes;
