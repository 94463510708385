import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../../components/DataTable';
import {
  archiveFeeStructure,
  getAllFeeStructures,
} from '../../../../controller';
import { FeeStructureInterface } from '../../../../types/FeeStructure';
import { useNavigate } from 'react-router-dom';
import {
  FEE_STRUCTURE_CREATE,
  FEE_STRUCTURE_UPDATE,
} from '../../../../routes/settings/FeeStructure';
import { notifyError, notifySuccess } from '../../../../components/CustomToast';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

const FeeStructureList: React.FC = () => {
  const navigate = useNavigate();
  const [feeStructures, setFeeStructures] = useState<FeeStructureInterface[]>(
    [],
  );
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchFeeStructures = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response = await getAllFeeStructures({ page, limit });
      const { success, data } = response;
      if (success) {
        setFeeStructures(data.feeStructures);
        setTotalRows(data.totalFeeStructures);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching fee structures:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeeStructures(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchFeeStructures(page, newPerPage);
  };

  const columns: TableColumn<FeeStructureInterface>[] = [
    {
      name: 'No.',
      cell: (_row: FeeStructureInterface, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
    },
    {
      name: 'Department',
      selector: (row: FeeStructureInterface) =>
        row.departmentDetails?.name || '',
      sortable: true,
    },
    {
      name: 'Standard',
      selector: (row: FeeStructureInterface) => row.standardDetails?.name || '',
      sortable: true,
    },
    {
      name: 'Medium',
      selector: (row: FeeStructureInterface) => row.mediumDetails?.name || '',
      sortable: true,
    },
    {
      name: 'Division',
      selector: (row: FeeStructureInterface) => row.divisionDetails?.name || '',
      sortable: true,
    },
    {
      name: 'Fee Amount',
      selector: (row: FeeStructureInterface) => row.fee_amount,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              row.id &&
              navigate(FEE_STRUCTURE_UPDATE.replace(':id', row.id?.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => row.id && handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete FeeStructure',
      'Are you sure you want to delete this FeeStructure?',
      async () => {
        try {
          const response = await archiveFeeStructure(id);
          if (response.success) {
            setFeeStructures((prevFS) => prevFS.filter((fs) => fs.id !== id));
            notifySuccess('FeeStructure deleted successfully!');
          } else {
            notifyError('Failed to delete FeeStructure');
          }
        } catch (error) {
          console.error('Error deleting FeeStructure:', error);
          notifyError('Error deleting FeeStructure');
        } finally {
          closeDialog();
        }
      },
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            Fee Structure List
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
              onClick={() => navigate(FEE_STRUCTURE_CREATE)}
            >
              Create
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={feeStructures}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default FeeStructureList;
