import { Branch } from '../../types/Branch';
import { GetRequest, Response } from '../../types/Comman';
import AxiosInstance from '../../utils/AxiosConfig';

const baseURL = 'branch';
// Organizztion
export const getAllBranch = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter, include, search } = body;
  let url = `${baseURL}/get-all-branches?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (include) {
    url += `&include=${include}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const archiveBranch = async (
  branchId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-branch/${branchId}`,
  );
  return response.data;
};

export const getArchiveBranch = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter } = body;
  let url = `${baseURL}/archived-branch?page=${page}&limit=${limit}`;

  if (
    filter &&
    filter?.column?.length > 0 &&
    filter?.operation?.length > 0 &&
    filter?.value?.length > 0
  ) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getBranchByID = async (
  branchId: number,
  include?: string,
): Promise<Response<any>> => {
  let url = `${baseURL}/get-branch/${branchId}`;
  if (include) {
    url += `?include=${include}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const createBranch = async (body: Branch): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/create-branch`, body);
  return response.data;
};

export const updateBranch = async (body: Branch): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-branch/${body.id}`,
    body,
  );
  return response.data;
};

export const restoreBranch = async (
  branchId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/restore-branch/${branchId}`,
  );
  return response.data;
};

export const permanentDeleteBranch = async (
  branchId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/permanentlyDelete-branch/${branchId}`,
  );
  return response.data;
};
