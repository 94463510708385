import React, { useState } from 'react';
import { TbReceiptRupee, TbReportMoney } from 'react-icons/tb';
import { BsChevronDown, BsChevronDoubleLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import {
  FcMoneyTransfer,
  FcOrganization,
  FcEngineering,
  FcReading,
  FcGenealogy,
  FcGraduationCap,
  FcCalendar,
  FcDonate,
} from 'react-icons/fc';
import {
  DEPARTMENT_LIST,
  DEPARTMENT_TITLE,
} from '../routes/settings/Department';
import { BRANCH_LIST, BRANCH_TITLE } from '../routes/branch/Branch';
import {
  ORGANIZATION_LIST,
  ORGANIZATION_TITLE,
} from '../routes/organization/Organization';
import { STANDARD_LIST, STANDARD_TITLE } from '../routes/settings/Standard';
import { DIVISION_LIST, DIVISION_TITLE } from '../routes/settings/Division';
import { MEDIUM_LIST, MEDIUM_TITLE } from '../routes/settings/Medium';
import { CATEGORY_LIST, CATEGORY_TITLE } from '../routes/settings/Category';
import { RELIGION_LIST, RELIGION_TITLE } from '../routes/settings/Religion';
import { SUBCAST_LIST, SUBCAST_TITLE } from '../routes/settings/Subcaste';
import { STUDENT_LIST, STUDENT_TITLE } from '../routes/student/Student';
import {
  FEE_STRUCTURE_LIST,
  FEE_STRUCTURE_TITLE,
} from '../routes/settings/FeeStructure';
import {
  FEE_CATEGORY_LIST,
  FEE_CATEGORY_TITLE,
} from '../routes/settings/FeeCategory';
import { FEE_COLLECTION } from '../routes/feeCollection/FeeCollection';
import { FEE_SCHEDULES_LIST } from '../routes/payment/FeeSchedules';
import { INSTALLMENT_LIST } from '../routes/payment/Installment';
import { SHIFT_LIST, SHIFT_TITLE } from '../routes/settings/Shift';

const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [wasManuallyExpanded, setWasManuallyExpanded] = useState(true);
  const location = useLocation();
  const user_type = localStorage.getItem('user_type');

  let logoTxt;

  if (user_type === 'SUPER_OWNER') {
    logoTxt = 'Super Owner';
  } else if (user_type === 'ORG_OWNER') {
    logoTxt = 'Organization';
  } else if (user_type === 'BRANCH_OWNER') {
    logoTxt = 'Branch';
  } else {
    logoTxt = 'School';
  }
  const handleMouseEnter = () => {
    if (!wasManuallyExpanded) {
      setIsExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!wasManuallyExpanded) {
      setIsExpanded(false);
    }
  };

  const handleToggleSidebar = () => {
    setIsExpanded(!isExpanded);
    setWasManuallyExpanded(!isExpanded);
  };
  return (
    <div
      className={`bg-[#32174D] h-screen ${isExpanded ? 'w-60' : 'w-20'} relative duration-300`}
    >
      <BsChevronDoubleLeft
        className={`duration-500 bg-gray-100 p-1 text-[#000000] text-3xl rounded-full absolute -right-4 top-12 border border-gray cursor-pointer ${!isExpanded && 'rotate-180'}`}
        onClick={handleToggleSidebar}
      />

      <div className={`inline-flex ${isExpanded ? 'pl-5' : 'pl-5'}`}>
        <FcGraduationCap
          className={`text-5xl text-gray-300 rounded block float-left mr-2 mt-2 duration-300 ${isExpanded && 'rotate-[360deg] mt-0'}`}
        />
        <h1
          className={`flex text-gray-300 origin-left font-medium text-xl duration-300 mt-2 items-center ${!isExpanded && 'scale-0'}`}
        >
          {logoTxt}
        </h1>
      </div>

      <div className="border-b border-dashed border-gray-300"></div>

      <div
        className="mt-5 px-3 max-h-[80%] overflow-y-auto hide-scrollbar"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="space-y-3">
          <p
            className={`text-gray-100 uppercase text-xs px-3 duration-300 ${!isExpanded && 'scale-0'}`}
          >
            analytics
          </p>
          <ul>
            {/* <Link to="/">
              <li
                className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/' ? 'bg-gray-900 text-white' : ''}`}
              >
                <FcOrgUnit className={`text-2xl`} />
                <span
                  className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  Dashboard
                </span>
              </li>
            </Link> */}
            {user_type === 'SUPER_OWNER' && (
              <Link to={ORGANIZATION_LIST}>
                <li
                  className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/organization' ? 'bg-gray-900 text-white' : ''}`}
                >
                  <FcOrganization className={`text-2xl`} />
                  <span
                    className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                  >
                    {ORGANIZATION_TITLE}
                  </span>
                </li>
              </Link>
            )}
            {(user_type === 'SUPER_OWNER' || user_type === 'ORG_OWNER') && (
              <Link to={BRANCH_LIST}>
                <li
                  className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/branch' ? 'bg-gray-900 text-white' : ''}`}
                >
                  <FcGenealogy className={`text-2xl rotate-90`} />
                  <span
                    className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                  >
                    {BRANCH_TITLE}
                  </span>
                </li>
              </Link>
            )}

            <li
              className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/setting') ? 'bg-gray-900 text-white' : ''}`}
            >
              <button
                className="flex w-full"
                onClick={() => {
                  setIsExpanded(true);
                  setSubmenuOpen(!submenuOpen);
                }}
              >
                <FcEngineering
                  className={`text-2xl duration-300 ${submenuOpen && 'rotate-180'}`}
                />
                <span
                  className={`mx-2 mt-[2px] flex flex-1 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  Setting
                </span>
                {isExpanded && (
                  <BsChevronDown
                    onClick={() => setSubmenuOpen(!submenuOpen)}
                    className={`duration-300 mt-1 ${submenuOpen && 'rotate-180'}`}
                  />
                )}
              </button>
            </li>
            {submenuOpen && isExpanded && (
              <ul>
                {/* <Link to="/setting/user">
                  <li className="text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-white hover:text-primary-600">
                    • User
                  </li>
                </Link>
                <Link to="/setting/role">
                  <li className="text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-white hover:text-primary-600">
                    • Role
                  </li>
                </Link> */}
                <Link to={DEPARTMENT_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/department') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {DEPARTMENT_TITLE}
                  </li>
                </Link>
                <Link to={STANDARD_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/standard') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {STANDARD_TITLE}
                  </li>
                </Link>
                <Link to={DIVISION_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/division') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {DIVISION_TITLE}
                  </li>
                </Link>
                <Link to={SHIFT_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/shift') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {SHIFT_TITLE}
                  </li>
                </Link>
                <Link to={MEDIUM_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/medium') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {MEDIUM_TITLE}
                  </li>
                </Link>
                <Link to={RELIGION_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/religion') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {RELIGION_TITLE}
                  </li>
                </Link>
                <Link to={CATEGORY_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/category') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {CATEGORY_TITLE}
                  </li>
                </Link>
                <Link to={SUBCAST_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/subcaste') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {SUBCAST_TITLE}
                  </li>
                </Link>
                <Link to={FEE_STRUCTURE_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/fee-structure') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {FEE_STRUCTURE_TITLE}
                  </li>
                </Link>
                <Link to={FEE_CATEGORY_LIST}>
                  <li
                    className={`text-gray-100 text-sm flex item-center gap-x-4 cursor-pointer p-2 px-10 rounded-md hover:bg-gray-900 hover:text-white ${location.pathname.startsWith('/fee-category') ? 'bg-gray-900 text-white' : ''}`}
                  >
                    • {FEE_CATEGORY_TITLE}
                  </li>
                </Link>
              </ul>
            )}
          </ul>
        </div>
        <div className="space-y-3 mt-5">
          <p
            className={`text-gray-100 uppercase text-xs px-3 duration-300 ${!isExpanded && 'scale-0'}`}
          >
            Student
          </p>
          <ul>
            <Link to={STUDENT_LIST}>
              <li
                className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/student' ? 'bg-gray-900 text-white' : ''}`}
              >
                <FcReading className={`text-2xl`} />
                <span
                  className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  {STUDENT_TITLE}
                </span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3 mt-5">
          <p
            className={`text-gray-100 uppercase text-xs px-3 duration-300 ${!isExpanded && 'scale-0'}`}
          >
            Fees Details
          </p>
          <ul>
            <Link to={FEE_COLLECTION}>
              <li
                className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/feeCollection' ? 'bg-gray-900 text-white' : ''}`}
              >
                <FcMoneyTransfer className={`text-2xl`} />
                <span
                  className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  Fee Collection
                </span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3 mt-5">
          <p
            className={`text-gray-100 uppercase text-xs px-3 duration-300 ${!isExpanded && 'scale-0'}`}
          >
            Payment
          </p>
          <ul>
            <Link to={FEE_SCHEDULES_LIST}>
              <li
                className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/feeSchedules' ? 'bg-gray-900 text-white' : ''}`}
              >
                <FcCalendar className={`text-2xl`} />
                <span
                  className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  Fee Schedules
                </span>
              </li>
            </Link>
            <Link to={INSTALLMENT_LIST}>
              <li
                className={`flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white ${location.pathname === '/installment' ? 'bg-gray-900 text-white' : ''}`}
              >
                <FcDonate className={`text-2xl`} />
                <span
                  className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
                >
                  Installment
                </span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="space-y-3 mt-5">
          <p
            className={`text-gray-100 uppercase text-xs px-3 duration-300 ${!isExpanded && 'scale-0'}`}
          >
            Report
          </p>
          <ul>
            <li className="flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white">
              <TbReportMoney className={`text-2xl`} />
              <span
                className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
              >
                Fee Collection Report
              </span>
            </li>
            <li className="flex items-center px-3 py-2 cursor-pointer duration-300 rounded-lg text-gray-300 hover:bg-gray-900 hover:text-white">
              <TbReceiptRupee className={`text-2xl`} />
              <span
                className={`mx-2 text-sm font-medium ${!isExpanded && 'hidden'}`}
              >
                Due Fees Report
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
