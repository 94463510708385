import { Navigate, Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar';
import NavBar from '../Navbar';

const Layout = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    console.log(token);
    return <Navigate to="/login" />;
  }
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow bg-gray-100 h-screen">
        <NavBar />
        <div className="h-[calc(100vh-65px)] overflow-y-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
