import { GetRequest, Response } from '../../types/Comman';
import { Organization } from '../../types/Organizations';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'organization';
// Organizztion
export const getOrganization = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit = 10, filter } = body;
  let url = `${baseURL}/get-all-organization?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const changeOrganizationStatus = async (
  organizationId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/toggle-organization-status/${organizationId}`,
  );
  return response.data;
};

export const archiveOrganization = async (
  organizationId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-organization/${organizationId}`,
  );
  return response.data;
};

export const getOrganizationByID = async (
  organizationId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-organization/${organizationId}`,
  );
  return response.data;
};

export const createOrganization = async (
  body: Organization,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-organization`,
    body,
  );
  return response.data;
};

export const updateOrganization = async (
  body: Organization,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-organization/${body.id}`,
    body,
  );
  return response.data;
};

export const getArchiveOrganization = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter } = body;
  let url = `${baseURL}/archived-organizations?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const restoreOrganization = async (
  organizationId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/restore-organizations/${organizationId}`,
  );
  return response.data;
};

export const permanentDeleteOrganization = async (
  organizationId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/permanentlyDelete-organizations/${organizationId}`,
  );
  return response.data;
};
