import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/fee/feeStructure/List';
import AddUpdate from '../../pages/settings/fee/feeStructure/AddUpdate';

export const FEE_STRUCTURE_TITLE = 'Fee Structure';
export const FEE_STRUCTURE_LIST = '/fee-structure';
export const FEE_STRUCTURE_CREATE = '/fee-structure/add';
export const FEE_STRUCTURE_UPDATE = '/fee-structure/:id/edit';

const FeeRoutes: RouteObject[] = [
  {
    path: FEE_STRUCTURE_LIST,
    element: <List />,
  },
  {
    path: FEE_STRUCTURE_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: FEE_STRUCTURE_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default FeeRoutes;
