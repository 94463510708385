import React, { useState, useEffect } from 'react';
import {
  getDepartment,
  getDivisionByStandard,
  getMediumByStandard,
  getSpecificStudents,
  getStandardByDepartment,
} from '../../controller';
import { AsyncPaginate } from 'react-select-async-paginate';
import { SingleValue } from 'react-select';
import { ResponseStudent } from '../../types/Student';

interface DropdownOption {
  id: number;
  name: string;
  is_active?: boolean;
  percentage?: number;
}

interface FormData {
  department_id: string;
  academic_year: string;
  standard_id: string;
  medium_id: string;
  division_id: string;
}

const AddUpdate: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    department_id: '',
    academic_year: '',
    standard_id: '',
    medium_id: '',
    division_id: '',
  });

  const [standardOptions, setStandardOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [loadingStandards, setLoadingStandards] = useState<boolean>(false);

  const [mediumOptions, setMediumOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [loadingMediums, setLoadingMediums] = useState<boolean>(false);

  const [divisionOptions, setDivisionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [loadingDivisions, setLoadingDivisions] = useState<boolean>(false);

  const [studentLoading, setStudentLoading] = useState<boolean>(false);
  const [students, setStudents] = useState<ResponseStudent[]>([]);
  const [studentOptions, setStudentOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const departmentloadOptions = async ({ page }: any) => {
    const { data } = await getDepartment({ page: 1, limit: 10 });
    return {
      options: data.departments.map((dep: DropdownOption) => ({
        label: dep.name,
        value: String(dep.id),
      })),
      hasMore: data.currentPage < data.totalPages,
      additional: {
        page: page + 1,
      },
    };
  };

  const academicYears = [
    { label: '2022-23', value: '2022-23' },
    { label: '2023-24', value: '2023-24' },
  ];

  // Fetch Standard Based on department selection
  useEffect(() => {
    const fetchStandards = async () => {
      if (formData.department_id) {
        setLoadingStandards(true);
        try {
          const response = await getStandardByDepartment(
            Number(formData.department_id),
          );
          setStandardOptions(
            response.data.standards.map((stan: DropdownOption) => ({
              label: stan.name,
              value: String(stan.id),
            })),
          );
        } catch (error) {
          console.error('Error fetching standards:', error);
        } finally {
          setLoadingStandards(false);
        }
      } else {
        setStandardOptions([]);
      }
    };
    fetchStandards();
  }, [formData.department_id]);

  // Fetch Medium options based on Department selection
  useEffect(() => {
    if (formData.department_id && formData.standard_id) {
      const fetchMediums = async () => {
        setLoadingMediums(true);
        try {
          const response = await getMediumByStandard(
            Number(formData.department_id),
            Number(formData.standard_id),
          );
          setMediumOptions(
            response.data.map(
              (item: {
                mediums: { id: number; name: string; is_active: boolean };
              }) => ({
                value: String(item.mediums.id),
                label: item.mediums.name,
                is_active: item.mediums.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching medium:', error);
        } finally {
          setLoadingMediums(false);
        }
      };
      fetchMediums();
    }
  }, [formData.standard_id, formData.department_id]);

  // Fetch Division options based on Standard selection
  useEffect(() => {
    if (formData.standard_id && formData?.department_id) {
      const fetchDivisions = async () => {
        try {
          const response = await getDivisionByStandard(
            Number(formData?.department_id),
            Number(formData.standard_id),
          );
          setDivisionOptions(
            response.data.map(
              (item: {
                divisions: { id: number; name: string; is_active: boolean };
              }) => ({
                value: String(item.divisions.id),
                label: item.divisions.name,
                is_active: item.divisions.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching division:', error);
        } finally {
          setLoadingDivisions(false);
        }
      };

      fetchDivisions();
    }
  }, [formData.standard_id, formData.department_id]);

  // Fetch Student Data Based on Depart
  useEffect(() => {
    const fetchSpecificStudents = async () => {
      const dId = formData.department_id;
      const mId = formData.medium_id;
      const sId = formData.standard_id;
      const divId = formData.division_id;

      if (dId && mId && sId && divId) {
        setStudentLoading(true);
        try {
          const response = await getSpecificStudents(
            Number(dId),
            Number(mId),
            Number(sId),
            Number(divId),
          );
          if (response.success) {
            setStudents(response.data.students);
            setStudentOptions(
              response.data.students.map((student: ResponseStudent) => ({
                label: `${student.first_name} ${student.middle_name ? student.middle_name + ' ' : ''}${student.last_name}, ${student.gr_number}`,
                value: String(student.id),
              })),
            );
          }
        } catch (error) {
          console.error('Error fetching specific students:', error);
          // Handle error notification if needed
        } finally {
          setStudentLoading(false);
        }
      } else {
        // Notify user to select all criteria
        console.warn('Please select all criteria');
      }
    };

    fetchSpecificStudents();
  }, [
    formData.department_id,
    formData.medium_id,
    formData.standard_id,
    formData.division_id,
  ]);

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              Fee Collection
            </h2>
          </div>
          <div className="p-5">
            <form>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label
                      htmlFor="departmentSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Department
                    </label>
                    <AsyncPaginate
                      loadOptions={departmentloadOptions}
                      additional={{
                        page: 1,
                      }}
                      placeholder="Select Department"
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          department_id: newValue ? newValue.value : '',
                          medium_id: '',
                          division_id: '',
                        });
                      }}
                    />
                  </div>
                  <div className="relative w-1/2">
                    <label
                      htmlFor="academicYearSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Academic Year
                    </label>
                    <AsyncPaginate
                      loadOptions={() =>
                        Promise.resolve({ options: academicYears })
                      }
                      placeholder="Select Academic Year"
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          academic_year: newValue ? newValue.value : '',
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-[32.5%]">
                    <label
                      htmlFor="standardSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Standard
                    </label>
                    <AsyncPaginate
                      key={formData.department_id}
                      loadOptions={() =>
                        Promise.resolve({ options: standardOptions })
                      }
                      placeholder={
                        formData.department_id
                          ? 'Select Standard'
                          : 'Please select Department'
                      }
                      isLoading={loadingStandards}
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          standard_id: newValue ? newValue.value : '',
                        });
                      }}
                      isDisabled={!formData.department_id}
                    />
                  </div>
                  <div className="relative w-[32.5%]">
                    <label
                      htmlFor="mediumSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Medium
                    </label>
                    <AsyncPaginate
                      key={formData.standard_id}
                      loadOptions={() =>
                        Promise.resolve({ options: mediumOptions })
                      }
                      placeholder={
                        formData.department_id && formData.standard_id
                          ? 'Select Medium'
                          : 'Please select Standard'
                      }
                      isLoading={loadingMediums}
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          medium_id: newValue ? newValue.value : '',
                        });
                      }}
                      isDisabled={
                        !formData.department_id || !formData.standard_id
                      }
                    />
                  </div>
                  <div className="relative w-[32.5%]">
                    <label
                      htmlFor="divisionSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Division
                    </label>
                    <AsyncPaginate
                      key={formData.standard_id}
                      loadOptions={() =>
                        Promise.resolve({ options: divisionOptions })
                      }
                      placeholder={
                        formData.department_id && formData.standard_id
                          ? 'Select Division'
                          : 'Please select Standard'
                      }
                      isLoading={loadingDivisions}
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          division_id: newValue ? newValue.value : '',
                        });
                      }}
                      isDisabled={
                        !formData.department_id || !formData.standard_id
                      }
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="w-1/2">
                    <label
                      htmlFor="studentSelect"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Student
                    </label>
                    <AsyncPaginate
                      key={formData.department_id}
                      loadOptions={() =>
                        Promise.resolve({ options: studentOptions })
                      }
                      placeholder="Select Student"
                      isLoading={studentLoading}
                      onChange={(newValue: SingleValue<{ value: string }>) => {
                        setFormData({
                          ...formData,
                          standard_id: newValue ? newValue.value : '',
                        });
                      }}
                      isDisabled={
                        !formData.department_id ||
                        !formData.standard_id ||
                        !formData.medium_id ||
                        !formData.division_id
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
