import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createFeeSchedules,
  installmentsAssign,
  getInstallment,
  getFeeSchedulesByID,
  updateFeeSchedules,
} from '../../../controller';
import { ResponseFeeSchedules } from '../../../types/FeeSchedules';
import { notifySuccess, notifyError } from '../../../components/CustomToast';
import {
  FEE_SCHEDULES_LIST,
  FEE_SCHEDULES_TITLE,
} from '../../../routes/payment/FeeSchedules';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { components } from 'react-select';

interface InstallmentInterface {
  value: number;
  label: string;
}

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<ResponseFeeSchedules>({
    id: 0,
    name: '',
    is_active: true,
  });
  const [loading, setLoading] = useState(false);

  const [selectedInstallments, setSelectedInstallments] = useState<
    InstallmentInterface[]
  >([]);

  useEffect(() => {
    if (isEdit && id) {
      const fetchFeeSchedules = async () => {
        setLoading(true);
        try {
          const response = await getFeeSchedulesByID(Number(id));
          const { success, data } = response;
          if (success) {
            setFormData(data);
          } else {
            console.error('Failed to fetch FeeSchedules:', response.error);
          }
        } catch (error) {
          console.error('Error fetching FeeSchedules:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchFeeSchedules();
    }
  }, [isEdit, id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const response = await updateFeeSchedules(formData);
        if (response.success) {
          notifySuccess('FeeSchedules Updated');
          navigate(FEE_SCHEDULES_LIST);
        } else {
          notifyError('FeeSchedules Updation Failed');
          console.error('Error submitting form:', response.error);
        }
      } else {
        const response = await createFeeSchedules(formData);
        if (response.success) {
          notifySuccess('FeeSchedules Created');
          navigate(FEE_SCHEDULES_LIST);
        } else {
          notifyError('FeeSchedules Creation Failed');
          console.error('Error submitting form:', response.error);
        }
      }
    } catch (error) {
      notifyError('Internal Server Error');
      console.error('Error submitting form:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const installmentloadOptions: LoadOptions<
    InstallmentInterface,
    any,
    { page: number }
  > = async (
    search: string,
    loadedOptions: readonly InstallmentInterface[],
    additional: { page: number } = { page: 1 },
  ) => {
    try {
      const response = await getInstallment({
        page: additional.page,
        limit: 10,
        search,
      });
      const { success, data } = response;
      if (success) {
        return {
          options: data.installment
            .filter((installment: any) => installment.is_active)
            .map((installment: any) => ({
              value: installment.id,
              label: installment.name,
            })),
          hasMore: response.totalPages > additional.page,
          additional: {
            page: additional.page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: additional.page + 1,
          },
        };
      }
    } catch (error) {
      console.error('Error fetching installments:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      };
    }
  };

  const assignInstallment = async () => {
    try {
      const data = {
        feeSchedule_id: Number(id),
        installments_ids: selectedInstallments.map(
          (installment) => installment.value,
        ),
      };
      const response = await installmentsAssign(data);
      if (response.success) {
        // setIsDivModalOpen(false);
        setSelectedInstallments([]);
        notifySuccess('Installments assigned successfully!');
      } else {
        notifyError('Failed to assign Installments');
      }
    } catch (error) {
      console.error('Error assigning Installments:', error);
      notifyError('Error assigning Installments');
    }
  };

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {FEE_SCHEDULES_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Fee Schedules Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(FEE_SCHEDULES_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>

            {isEdit && (
              <>
                <div className="bg-gray-100 p-5 rounded mt-5">
                  <h2 className="text-base font-medium text-gray-900 mb-2">
                    Select Installment For Assign Fee Schedules
                  </h2>
                  <div className="flex justify-between">
                    <div className="w-9/12">
                      <AsyncPaginate
                        isMulti
                        required
                        value={selectedInstallments}
                        loadOptions={installmentloadOptions}
                        additional={{ page: 1 }}
                        onChange={(value) =>
                          setSelectedInstallments(
                            value as InstallmentInterface[],
                          )
                        }
                        components={{
                          MultiValue: components.MultiValue,
                          Option: components.Option,
                        }}
                        closeMenuOnSelect={false}
                      />
                    </div>
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded-md ml-2"
                      onClick={assignInstallment}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
