import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createStandard,
  // divisionsAssign,
  getDivision,
  getStandardByID,
  updateStandard,
} from '../../../controller';
import { ResponseStandard } from '../../../types/Standard';
import { notifySuccess, notifyError } from '../../../components/CustomToast';
import {
  STANDARD_LIST,
  STANDARD_TITLE,
} from '../../../routes/settings/Standard';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { components } from 'react-select';
import axios from 'axios';

interface DivisionInterface {
  value: number;
  label: string;
}

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<ResponseStandard>({
    id: 0,
    name: '',
    is_active: true,
  });
  const [loading, setLoading] = useState(false);

  // const [selectedDivisions, setSelectedDivisions] = useState<
  //   DivisionInterface[]
  // >([]);

  useEffect(() => {
    if (isEdit && id) {
      const fetchStandard = async () => {
        setLoading(true);
        try {
          const response = await getStandardByID(Number(id));
          const { success, data } = response;
          if (success) {
            setFormData(data);
          } else {
            console.error('Failed to fetch standard:', response.error);
          }
        } catch (error) {
          console.error('Error fetching standard:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchStandard();
    }
  }, [isEdit, id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const response = await updateStandard(formData);
        if (response.success) {
          notifySuccess('Standard Updated');
          navigate(STANDARD_LIST);
        } else {
          notifyError('Standard Updation Failed');
          console.error('Error submitting form:', response.error);
        }
      } else {
        const response = await createStandard(formData);
        if (response.success) {
          notifySuccess('Standard Created');
          navigate(STANDARD_LIST);
        } else {
          notifyError('Standard Creation Failed');
          console.error('Error submitting form:', response.error);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notifyError(error.response.data.message);
        } else {
          notifyError('Internal Server Error');
        }
      } else {
        notifyError('An unexpected error occurred');
      }
      console.error('Error submitting form:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const divisionloadOptions: LoadOptions<
    DivisionInterface,
    any,
    { page: number }
  > = async (
    search: string,
    loadedOptions: readonly DivisionInterface[],
    additional: { page: number } = { page: 1 },
  ) => {
    try {
      const { data, success } = await getDivision({
        page: additional.page,
        limit: 10,
        search,
      });
      if (success) {
        return {
          options: data.divisions.map((division: any) => ({
            value: division.id,
            label: division.name,
          })),
          hasMore: data.totalPages > additional.page,
          additional: {
            page: additional.page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: additional.page + 1,
          },
        };
      }
    } catch (error) {
      console.error('Error fetching divisions:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      };
    }
  };

  // const assignDivisions = async () => {
  //   try {
  //     const data = {
  //       standard_id: Number(id),
  //       division_ids: selectedDivisions.map((division) => division.value),
  //     };
  //     const response = await divisionsAssign(data);
  //     if (response.success) {
  //       setSelectedDivisions([]);
  //       notifySuccess('Divisions assigned successfully!');
  //     } else {
  //       notifyError('Failed to assign Divisions');
  //     }
  //   } catch (error) {
  //     console.error('Error assigning Divisions:', error);
  //     notifyError('Error assigning Divisions');
  //   }
  // };

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {STANDARD_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Standard Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(STANDARD_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>

            {/* {isEdit && (
              <>
                <div className="bg-gray-100 p-5 rounded mt-5">
                  <h2 className="text-base font-medium text-gray-900 mb-2">
                    Select Division For Assign Standards
                  </h2>
                  <div className="flex justify-between">
                    <div className="w-9/12">
                      <AsyncPaginate
                        isMulti
                        required
                        value={selectedDivisions}
                        loadOptions={divisionloadOptions}
                        additional={{ page: 1 }}
                        onChange={(value) =>
                          setSelectedDivisions(value as DivisionInterface[])
                        }
                        components={{
                          MultiValue: components.MultiValue,
                          Option: components.Option,
                        }}
                        closeMenuOnSelect={false}
                      />
                    </div>
                    <button
                      className="bg-blue-500 text-white py-1 px-3 rounded-md ml-2"
                      // onClick={assignDivisions}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
