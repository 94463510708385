import { RouteObject } from 'react-router-dom';
import AddUpdate from '../../pages/feeCollection/AddUpdate';

export const FEE_COLLECTION = '/feeCollection';

const StudentRoutes: RouteObject[] = [
  {
    path: FEE_COLLECTION,
    element: <AddUpdate />,
  },
];

export default StudentRoutes;
