import { GetRequest } from '../../types/Comman';
import { ResponseFeeCategory } from '../../types/FeeCategory';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'feeCategory';

// Create New Fee Category
export const createFeeCategory = async (
  body: ResponseFeeCategory,
): Promise<any> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-feeCategory`,
    body,
  );
  return response.data;
};

// Get All Fee Category
export const getFeeCategory = async (body: GetRequest): Promise<any> => {
  const { page, limit } = body;
  const url = `${baseURL}/get-all-feeCategory?page=${page}&limit=${limit}`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getFeeCategoryByID = async (
  FeeCategoryId: number,
): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-feeCategory/${FeeCategoryId}`,
  );
  return response.data;
};

export const updateFeeCategory = async (
  body: ResponseFeeCategory,
): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-feeCategory/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveFeeCategory = async (
  FeeCategoryId: number,
): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-feeCategory/${FeeCategoryId}`,
  );
  return response.data;
};
