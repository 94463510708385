import { GetRequest } from '../../types/Comman';
import {
  ResponseInstallment,
  assignInstallments,
} from '../../types/Installment';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'installment';

export const createInstallment = async (
  body: ResponseInstallment,
): Promise<any> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-installment`,
    body,
  );
  return response.data;
};

export const getInstallment = async (body: GetRequest): Promise<any> => {
  const { page, limit } = body;
  const url = `${baseURL}/get-all-installment?page=${page}&limit=${limit}`;

  const response = await AxiosInstance.get(url);
  return response.data;
};

export const updateInstallment = async (
  body: ResponseInstallment,
): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-installment/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveInstallment = async (id: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-installment/${id}`,
  );
  return response.data;
};

export const installmentsAssign = async (body: assignInstallments) => {
  const response = await AxiosInstance.post(
    `${baseURL}/assign-installments-to-feeSchedules`,
    body,
  );
  return response.data;
};

export const getInstallmentByID = async (Id: number): Promise<any> => {
  const response = await AxiosInstance.get(`${baseURL}/get-installment/${Id}`);
  return response.data;
};
