import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../../components/DataTable';
import { archiveFeeCategory, getFeeCategory } from '../../../../controller';
import { notifyError, notifySuccess } from '../../../../components/CustomToast';
import { useNavigate } from 'react-router-dom';
import {
  FEE_CATEGORY_CREATE,
  FEE_CATEGORY_UPDATE,
} from '../../../../routes/settings/FeeCategory';
import { ResponseFeeCategory } from '../../../../types/FeeCategory';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

const FeeCategoryList: React.FC = () => {
  const navigate = useNavigate();
  const [feeCategory, setFeeCategory] = useState<ResponseFeeCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchFeeCategory = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response = await getFeeCategory({ page, limit });
      const { success, data } = response;
      if (success) {
        setFeeCategory(data.feeCategories);
        setTotalRows(data.totalFeeCategory);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching fee category:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeeCategory(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchFeeCategory(page, newPerPage);
  };

  const columns: TableColumn<ResponseFeeCategory>[] = [
    {
      name: 'No.',
      cell: (_row: ResponseFeeCategory, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
    },
    {
      name: 'Fee Category',
      selector: (row: ResponseFeeCategory) => row.name,
      sortable: true,
    },
    {
      name: 'Percentage',
      selector: (row: ResponseFeeCategory) => row.percentage,
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: ResponseFeeCategory) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              row.id &&
              navigate(FEE_CATEGORY_UPDATE.replace(':id', row.id.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => row.id && handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete FeeCategory',
      'Are you sure you want to delete this FeeCategory?',
      async () => {
        try {
          const result = await archiveFeeCategory(id);
          if (result.success) {
            // Remove the deleted feeCategory from the list
            setFeeCategory((prevFC) =>
              prevFC.filter((feeCategory) => feeCategory.id !== id),
            );
            notifySuccess('FeeCategory deleted successfully!');
          } else {
            notifyError('Failed to delete FeeCategory');
          }
        } catch (error) {
          console.error('Error deleting FeeCategory:', error);
          notifyError('Error deleting FeeCategory');
        } finally {
          closeDialog();
        }
      },
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            Fee Category List
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
              onClick={() => navigate(FEE_CATEGORY_CREATE)}
            >
              Create
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={feeCategory}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default FeeCategoryList;
