import { ResponseCategory } from '../../types/Category';
import { GetRequest, Response } from '../../types/Comman';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'category';

// Category
export const getCategory = async (): Promise<Response<any>> => {
  const url = `${baseURL}/get-all-category`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getAllCategory = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit = 10, filter } = body;
  const url = `${baseURL}/get-all-category?page=${page}&limit=${limit}`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const createCategory = async (
  body: ResponseCategory,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/create-category`, body);
  return response.data;
};

export const updateCategory = async (
  body: ResponseCategory,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-category/${body.id}`,
    body,
  );
  return response.data;
};

export const deleteCategory = async (
  categoryId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-category/${categoryId}`,
  );
  return response.data;
};

export const getCategoryByID = async (
  CategoryId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-category/${CategoryId}`,
  );
  return response.data;
};
