import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/subcaste/List';
import AddUpdate from '../../pages/settings/subcaste/AddUpdate';

export const SUBCAST_TITLE = 'Subcaste';
export const SUBCAST_LIST = '/subcaste';
export const SUBCAST_CREATE = '/subcaste/add';
export const SUBCAST_UPDATE = '/subcaste/:id/edit';

const SubcastRoutes: RouteObject[] = [
  {
    path: SUBCAST_LIST,
    element: <List />,
  },
  {
    path: SUBCAST_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: SUBCAST_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default SubcastRoutes;
