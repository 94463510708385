import AxiosInstance from '../../utils/AxiosConfig';
import { ResponseReligion } from '../../types/Religion';
import { GetRequest, Response } from '../../types/Comman';

const baseURL = 'religion';

// Religion
export const createReligion = async (
  body: ResponseReligion,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/create-religion`, body);
  return response.data;
};

export const updateReligion = async (
  body: ResponseReligion,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-religion/${body.id}`,
    body,
  );
  return response.data;
};

export const getReligion = async (): Promise<Response<any>> => {
  const url = `${baseURL}/get-all-religion`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const archiveReligion = async (
  religionId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-religion/${religionId}`,
  );
  return response.data;
};

export const getAllReligion = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter, include } = body;
  let url = `${baseURL}/get-all-religion?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (include) {
    url += `&include=${include}`;
  }

  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getReligionByID = async (
  ReligionId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-religion/${ReligionId}`,
  );
  return response.data;
};
