import React, { useLayoutEffect, useState } from 'react';
import { fetchProfile } from '../../controller';
import { User } from '../../types/User';

const Profile: React.FC = () => {
  const [user, setuser] = useState<User | null>(null);
  useLayoutEffect(() => {
    fetchProfileData();
  });

  const fetchProfileData = async () => {
    const response = await fetchProfile();
    setuser(response);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-12">
      <div className="flex items-center justify-center">
        <img
          className="w-24 h-24 rounded-full"
          src="https://via.placeholder.com/150"
          alt="Profile"
        />
      </div>
      <div className="mt-6 text-center">
        <h2 className="text-xl font-semibold text-gray-900">{user?.email}</h2>
        <p className="text-gray-600">{user?.user_name}</p>
        <div className="mt-6">
          <button className="px-4 py-2 bg-blue-600 text-white rounded-lg">
            Edit Profile
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
