import AxiosInstance from '../../utils/AxiosConfig';
import { ShiftInterface } from '../../types/Shift';
import { GetRequest } from '../../types/Comman';

const baseURL = 'shift';

// Shift
export const createShift = async (body: ShiftInterface): Promise<any> => {
  const response = await AxiosInstance.post(`${baseURL}/create-shift`, body);
  return response.data;
};

export const updateShift = async (body: ShiftInterface): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-shift/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveShift = async (shiftId: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-shift/${shiftId}`,
  );
  return response.data;
};

export const getAllShift = async (body: GetRequest): Promise<any> => {
  const { page, limit, filter, include } = body;
  let url = `${baseURL}/get-all-shift?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (include) {
    url += `&include=${include}`;
  }

  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getShiftByID = async (ShiftId: number): Promise<any> => {
  const response = await AxiosInstance.get(`${baseURL}/get-shift/${ShiftId}`);
  return response.data;
};
