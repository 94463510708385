import { GetRequest } from '../../types/Comman';
import { ResponseStandard, assignStandards } from '../../types/Standard';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'standard';

export const getStandard = async (body: GetRequest): Promise<any> => {
  const { page, limit, filter, search } = body;
  let url = `${baseURL}/get-all-standard?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getStandardByDepartment = async (
  departmentId: number,
): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/standardsbydepartment/${departmentId}`,
  );
  return response.data;
};

// export const getSearchStandard = async (body: GetRequest): Promise<any> => {
//   const { page, limit, search } = body;
//   const response = await AxiosInstance.get(
//     `${baseURL}/get-all-standard?page=${page}&limit=${limit}&search=${search}`,
//   );
//   return response.data;
// };

export const standardsAssign = async (body: assignStandards) => {
  const response = await AxiosInstance.post(`${baseURL}/assign-standard`, body);
  return response.data;
};

export const createStandard = async (body: ResponseStandard): Promise<any> => {
  const response = await AxiosInstance.post(`${baseURL}/create-standard`, body);
  return response.data;
};

export const updateStandard = async (body: ResponseStandard): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-standard/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveStandart = async (standartId: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-standard/${standartId}`,
  );
  return response.data;
};

export const getStandardByID = async (StandardId: number): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-standard/${StandardId}`,
  );
  return response.data;
};
