import { RouteObject } from 'react-router-dom';
import List from '../../pages/student/List';
import View from '../../pages/student/View';
import AddUpdate from '../../pages/student/AddUpdate';

export const STUDENT_TITLE = 'Student';
export const STUDENT_LIST = '/student';
export const STUDENT_ARCHIVE_LIST = '/student/archive';
export const STUDENT_CREATE = '/student/add';
export const STUDENT_UPDATE = '/student/:id/edit';
export const STUDENT_VIEW = '/student/:id/view';

const StudentRoutes: RouteObject[] = [
  {
    path: STUDENT_LIST,
    element: <List isArchive={false} />,
  },
  {
    path: STUDENT_ARCHIVE_LIST,
    element: <List isArchive={true} />,
  },
  {
    path: STUDENT_VIEW,
    element: <View />,
  },
  {
    path: STUDENT_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: STUDENT_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default StudentRoutes;
