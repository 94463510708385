import { Outlet, Navigate } from 'react-router-dom';

function AuthLayout() {
  if (localStorage.getItem('token')) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white w-full">
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
