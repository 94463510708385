import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/religion/List';
import AddUpdate from '../../pages/settings/religion/AddUpdate';

export const RELIGION_TITLE = 'Religion';
export const RELIGION_LIST = '/religion';
export const RELIGION_CREATE = '/religion/add';
export const RELIGION_UPDATE = '/religion/:id/edit';

const RegligionRoutes: RouteObject[] = [
  {
    path: RELIGION_LIST,
    element: <List />,
  },
  {
    path: RELIGION_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: RELIGION_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default RegligionRoutes;
