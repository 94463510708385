import { RouteObject } from 'react-router-dom';
import List from '../../pages/branch/List';
import AddUpdate from '../../pages/branch/AddUpdate';
import View from '../../pages/branch/View';

export const BRANCH_TITLE = 'Branch';
export const BRANCH_LIST = '/branch';
export const BRANCH_ARCHIVE_LIST = '/branch/archive';
export const BRANCH_CREATE = '/branch/add';
export const BRANCH_VIEW = '/branch/:id/view';
export const BRANCH_UPDATE = '/branch/:id/edit';

const BranchRoutes: RouteObject[] = [
  {
    path: BRANCH_LIST,
    element: <List isArchive={false} />,
  },
  {
    path: BRANCH_ARCHIVE_LIST,
    element: <List isArchive={true} />,
  },
  {
    path: BRANCH_VIEW,
    element: <View />,
  },
  {
    path: BRANCH_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: BRANCH_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default BranchRoutes;
