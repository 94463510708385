import { GetRequest } from '../../types/Comman';
import { ResponseSubCaste } from '../../types/SubCaste';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'sub-caste';

// subCaste
export const getSubCaste = async (): Promise<any> => {
  const url = `${baseURL}/get-all-subCaste`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getAllSubCaste = async (body: GetRequest): Promise<any> => {
  const { page, limit, filter, include } = body;
  let url = `${baseURL}/get-all-subCaste?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (include) {
    url += `&include=${include}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const createSubCaste = async (body: ResponseSubCaste): Promise<any> => {
  const response = await AxiosInstance.post(`${baseURL}/create-subCaste`, body);
  return response.data;
};

export const updateSubCaste = async (body: ResponseSubCaste): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-subCaste/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveSubCaste = async (subCastId: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-subCaste/${subCastId}`,
  );
  return response.data;
};

export const getSubCasteByID = async (SubCastId: number): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-subCaste/${SubCastId}`,
  );
  return response.data;
};
