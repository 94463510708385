// src/axiosSetup.js
import axios from 'axios';

// Create an instance of axios
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

// Request Interceptor
AxiosInstance.interceptors.request.use(
  (config) => {
    // Add auth token or any custom config here
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response Interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_type');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default AxiosInstance;
