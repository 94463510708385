import React from 'react';

interface ConfirmationDialogProps {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  title,
  message,
  onConfirm,
  onCancel,
  isOpen,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black opacity-50 transition-opacity"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-11/12 md:w-1/3 lg:w-1/3 transform transition-all duration-300 ease-in-out">
        <h3 className="text-xl font-semibold mb-4 text-gray-800">{title}</h3>
        <p className="mb-6 text-gray-600">{message}</p>
        <div className="flex justify-end">
          <button
            className="bg-gray-400 text-white px-4 py-2 rounded mr-2 hover:bg-gray-500 transition-colors duration-200"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200"
            onClick={onConfirm}
          >
            Yes, I&apos;m Sure
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
