import { RequestAuth, ResponseAuth } from '../../types/Auth';
import { Response } from '../../types/Comman';
import { User } from '../../types/User';
import AxiosInstance from '../../utils/AxiosConfig';

// auth
export const authUser = async (
  body: RequestAuth,
): Promise<Response<ResponseAuth>> => {
  const { data } = await AxiosInstance.post<Response<ResponseAuth>>(
    '/auth/login',
    body,
  );
  return data;
};

export const fetchProfile = async () => {
  const { data } = await AxiosInstance.get<Response<User>>('/users/profile');
  return data.data;
};
