import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaRegEdit, FaEye } from 'react-icons/fa';
import { MdDelete, MdRestoreFromTrash } from 'react-icons/md';
import CustomDataTable from '../../components/DataTable';
import { useNavigate } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import CustomFilter from '../../components/CustomFilter';
import {
  BRANCH_ARCHIVE_LIST,
  BRANCH_CREATE,
  BRANCH_LIST,
  BRANCH_TITLE,
  BRANCH_UPDATE,
  BRANCH_VIEW,
} from '../../routes/branch/Branch';
import {
  archiveBranch,
  getAllBranch,
  permanentDeleteBranch,
  restoreBranch,
} from '../../controller';
import { Branch } from '../../types/Branch';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { notifyError, notifySuccess } from '../../components/CustomToast';

interface FilterCriteria {
  column: string[];
  operation: string[];
  value: string | string[];
}

const List: React.FC<{ isArchive?: boolean }> = ({ isArchive = false }) => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    column: [],
    operation: [],
    value: [],
  });
  const organizationInclude = 'organization';

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchBranches = async (
    page: number,
    limit: number,
    filter?: FilterCriteria,
    include?: string,
  ) => {
    setLoading(true);
    try {
      if (isArchive) {
        setBranches([]);
        setTotalRows(1);
        setCurrentPage(1);
        // const response = await getArchiveBranch({ page, limit, filter });
        // if (response.success) {
        //   const branchesWithOrgNames = await Promise.all(
        //     response.branches.map(async (branch: Branch) => {
        //       try {
        //         if (branch.organization_id) {
        //           const orgResponse = await getOrganizationByID(
        //             branch.organization_id,
        //           );
        //           const orgResult = await orgResponse.json();
        //           if (orgResult.success) {
        //             return {
        //               ...branch,
        //               organization_name:
        //                 orgResult.organization.organization_name,
        //             };
        //           }
        //         }
        //       } catch (error) {
        //         console.error('Error fetching organization details:', error);
        //       }
        //       return branch;
        //     }),
        //   );
        //   setBranches(branchesWithOrgNames);
        //   setTotalRows(response.totalBranches);
        //   setCurrentPage(response.currentPage);
        // }
      } else {
        const { success, data } = await getAllBranch({
          page,
          limit,
          filter,
          include,
        });
        if (success) {
          setBranches(data.branches);
          setTotalRows(data.totalBranches);
          setCurrentPage(data.currentPage);
        }
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBranches(
      currentPage,
      perPage,
      filterCriteria || undefined,
      organizationInclude,
    );
    // eslint-disable-next-line
  }, [currentPage, perPage, filterCriteria, isArchive]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchBranches(page, newPerPage, filterCriteria, organizationInclude);
  };

  const handleRestore = async (id: number) => {
    openDialog(
      'Restore Branch',
      'Are you sure you want to restore this branch?',
      async () => {
        try {
          if (isArchive) {
            const response = await restoreBranch(id);
            if (response.success) {
              fetchBranches(
                currentPage,
                perPage,
                filterCriteria || undefined,
                organizationInclude,
              );
              notifySuccess('Branch Restored Successfully');
            }
          }
        } catch (error) {
          console.error('Error restoring branch:', error);
          notifyError('Error Restoring Branch');
        } finally {
          closeDialog();
        }
      },
    );
  };

  const handlePermanentDelete = async (id: number) => {
    openDialog(
      'Permanently Delete Branch',
      'Are you sure you want to permanently delete this branch?',
      async () => {
        try {
          const { success, message } = await permanentDeleteBranch(id);
          if (success) {
            fetchBranches(
              currentPage,
              perPage,
              filterCriteria || undefined,
              organizationInclude,
            );
            notifySuccess('Branch Permanently Deleted');
          } else {
            notifyError('Error deleting branch');
            console.error('Error deleting branch:', message);
          }
        } catch (error) {
          notifyError('Error deleting branch');
          console.error('Error deleting branch:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  const columns: TableColumn<Branch>[] = [
    {
      name: 'No.',
      cell: (_row: Branch, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
      width: '68px',
    },
    {
      name: 'Organization Name',
      selector: (row: Branch) => row?.organization?.name,
      sortable: true,
    },
    {
      name: 'Branch Name',
      selector: (row: Branch) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: Branch) => row.email,
      sortable: true,
    },
    {
      name: 'Established Date',
      selector: (row: Branch) =>
        new Date(row.established_date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: Branch) => (row.is_active ? 'Active' : 'Inactive'),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end mr-3">
          {isArchive ? (
            <>
              <MdRestoreFromTrash
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() => handleRestore(Number(row?.id))}
              />
              <MdDelete
                className="text-xl text-red-500 cursor-pointer"
                onClick={() => handlePermanentDelete(Number(row.id))}
              />
            </>
          ) : (
            <>
              <FaEye
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() =>
                  row?.id &&
                  navigate(BRANCH_VIEW.replace(':id', row?.id?.toString()))
                }
              />
              <FaRegEdit
                className="text-xl text-blue-500 cursor-pointer mr-1"
                onClick={() =>
                  row?.id &&
                  navigate(BRANCH_UPDATE.replace(':id', row?.id?.toString()))
                }
              />
              <MdDelete
                className="text-xl text-red-500 cursor-pointer"
                onClick={() => row.id && handleDelete(row.id)}
              />
            </>
          )}
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Branch',
      'Are you sure you want to delete this branch?',
      async () => {
        try {
          const response = await archiveBranch(id);
          if (response.success) {
            // Remove the deleted branch from the list
            setBranches((prevBranches) =>
              prevBranches.filter((branch) => branch.id !== id),
            );
            notifySuccess('Branch deleted successfully!');
          } else {
            notifyError('Failed to delete branch');
          }
        } catch (error) {
          console.error('Error deleting branch:', error);
          notifyError('Error deleting branch');
        } finally {
          closeDialog();
        }
      },
    );
  };

  const formatDate = (dateString: string | string[]) => {
    if (Array.isArray(dateString)) {
      // If dateString is already an array, return the first element
      return dateString[0];
    } else {
      // If dateString is a single string, format it and return
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    }
  };

  const handleApplyFilter = async (filter: FilterCriteria) => {
    // Format date before sending to server
    const formattedFilter = {
      ...filter,
      value: filter.value ? formatDate(filter.value) : '',
    };
    setFilterCriteria(formattedFilter);
    setShowFilter(false);
  };

  const branchColumns = [
    { value: 'organization_name', label: 'Organization Name' },
    { value: 'branch_name', label: 'Branch Name' },
    { value: 'email', label: 'Email' },
    { value: 'established_date', label: 'Established Date' },
    { value: 'is_active', label: 'Active Status' },
  ];

  const operations = [
    { value: 'equals', label: 'Equals' },
    { value: 'not_equals', label: 'Not Equals' },
    { value: 'like', label: 'Like' },
    { value: 'greater_than', label: 'Greater Than' },
    { value: 'less_than', label: 'Less Than' },
  ];

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            {isArchive
              ? `Archive ${BRANCH_TITLE} List`
              : `${BRANCH_TITLE} List`}
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-blue-500 ml-2 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white flex"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FiFilter className="m-1" />
            </button>
            {isArchive ? (
              <button
                className="bg-gray-900 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-gray-700 text-white"
                onClick={() => navigate(BRANCH_LIST)}
              >
                Back
              </button>
            ) : (
              <>
                <button
                  className="bg-green-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-green-600 text-white"
                  onClick={() => navigate(BRANCH_CREATE)}
                >
                  Create
                </button>
                <button
                  className="bg-red-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-red-600 text-white"
                  onClick={() => navigate(BRANCH_ARCHIVE_LIST)}
                >
                  Archive
                </button>
              </>
            )}
          </div>
        </div>
        {showFilter && (
          <CustomFilter
            columns={branchColumns}
            operations={operations}
            onApplyFilter={handleApplyFilter}
          />
        )}
        <div>
          <CustomDataTable
            columns={columns}
            data={branches}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
