import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createInstallment,
  getInstallmentByID,
  updateInstallment,
} from '../../../controller';
import { ResponseInstallment } from '../../../types/Installment';
import { notifySuccess, notifyError } from '../../../components/CustomToast';
import {
  INSTALLMENT_LIST,
  INSTALLMENT_TITLE,
} from '../../../routes/payment/Installment';

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<ResponseInstallment>({
    id: 0,
    name: '',
    amount: 0,
    due_date: null,
    is_active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit && id) {
      const fetchInstallment = async () => {
        setLoading(true);
        try {
          const response = await getInstallmentByID(Number(id));
          const { success, data } = response;
          if (success) {
            setFormData({
              ...data,
              due_date: data.due_date
                ? new Date(data.due_date).toISOString().split('T')[0]
                : null,
            });
          } else {
            console.error('Failed to fetch Installment:', response.error);
          }
        } catch (error) {
          console.error('Error fetching Installment:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchInstallment();
    }
  }, [isEdit, id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const response = await updateInstallment(formData);
        if (response.success) {
          notifySuccess('Installment Updated');
          navigate(INSTALLMENT_LIST);
        } else {
          notifyError('Installment Updation Failed');
          console.error('Error submitting form:', response.error);
        }
      } else {
        const response = await createInstallment(formData);
        if (response.success) {
          notifySuccess('Installment Created');
          navigate(INSTALLMENT_LIST);
        } else {
          notifyError('Installment Creation Failed');
          console.error('Error submitting form:', response.error);
        }
      }
    } catch (error) {
      notifyError('Internal Server Error');
      console.error('Error submitting form:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {INSTALLMENT_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Installment Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <input
                      type="number"
                      name="amount"
                      value={formData.amount}
                      onChange={handleChange}
                      placeholder=" "
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Amount *
                    </span>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="due_date"
                        value={formData.due_date ?? ''}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                        onFocus={(e) => (e.target.type = 'date')}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            e.target.type = 'text';
                          }
                        }}
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Due Date *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(INSTALLMENT_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
