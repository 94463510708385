import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { IoIosPower, IoMdSettings } from 'react-icons/io';
import { PiUserCircleDuotone } from 'react-icons/pi';
import {
  ORGANIZATION_LIST,
  ORGANIZATION_TITLE,
} from '../routes/organization/Organization';
import { BRANCH_LIST, BRANCH_TITLE } from '../routes/branch/Branch';
import logo from '../assets/images/logo.png';
import { LOGIN } from '../routes/AppRoutes';
import { notifySuccess } from './CustomToast';

const NavBar: React.FC = () => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const profileDropdownRef = useRef<HTMLDivElement>(null);

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_type');
    navigate(LOGIN);
    notifySuccess('Logout Successfully');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsProfileOpen(false);
      }
    };

    if (isProfileOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isProfileOpen]);

  return (
    <nav className="bg-[#552887] border-b border-dashed border-gray-300">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className={`h-6 w-6 ${isMobileMenuOpen ? 'hidden' : 'block'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
              <svg
                className={`h-6 w-6 ${isMobileMenuOpen ? 'block' : 'hidden'}`}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="hidden sm:ml-6 sm:block">
              <div className="flex space-x-4">
                <Link
                  to={ORGANIZATION_LIST}
                  className={`${
                    location.pathname === ORGANIZATION_LIST
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  } rounded-md px-3 py-2 text-sm font-medium`}
                >
                  {ORGANIZATION_TITLE}
                </Link>
                <Link
                  to={BRANCH_LIST}
                  className={`${
                    location.pathname === BRANCH_LIST
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white'
                  } rounded-md px-3 py-2 text-sm font-medium`}
                >
                  {BRANCH_TITLE}
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* profile dropdown */}
            <div className="relative ml-3" ref={profileDropdownRef}>
              <button
                type="button"
                className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                id="user-menu-button"
                aria-expanded={isProfileOpen ? 'true' : 'false'}
                aria-haspopup="true"
                onClick={toggleProfileDropdown}
              >
                <img className="h-8 w-8 rounded-full" src={logo} alt="" />
              </button>
              {/* Dropdown menu */}
              {isProfileOpen && (
                <div
                  className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabIndex={-1}
                >
                  <span
                    className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-0"
                  >
                    <PiUserCircleDuotone className="text-lg mr-2" /> Your
                    Profile
                  </span>
                  <span
                    className="flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-1"
                  >
                    <IoMdSettings className="text-lg mr-2" /> Settings
                  </span>
                  <button
                    className="flex px-4 w-full text-left py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                    id="user-menu-item-2"
                    onClick={handleLogout}
                  >
                    <IoIosPower className="text-lg mr-2" /> Sign out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div className="sm:hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2">
            <Link
              to={ORGANIZATION_LIST}
              className={`${
                location.pathname === ORGANIZATION_LIST
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              } block rounded-md px-3 py-2 text-base font-medium`}
            >
              {ORGANIZATION_TITLE}
            </Link>
            <Link
              to={BRANCH_LIST}
              className={`${
                location.pathname === BRANCH_LIST
                  ? 'bg-gray-900 text-white'
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'
              } block rounded-md px-3 py-2 text-base font-medium`}
            >
              {BRANCH_TITLE}
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
