import { GetRequest, Response } from '../../types/Comman';
import { ResponseDepartment } from '../../types/Department';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'department';

// Department
export const getDepartment = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter, include } = body;
  let url = `${baseURL}/get-all-department?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (include) {
    url += `&include=${include}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const archiveDepartment = async (
  departmentId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-department/${departmentId}`,
  );
  return response.data;
};

export const getDepartmentID = async (
  departmentId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-department/${departmentId}`,
  );
  return response.data;
};

export const createDepartment = async (
  body: ResponseDepartment,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-department`,
    body,
  );
  return response.data;
};

export const updateDepartment = async (
  body: ResponseDepartment,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-department/${body.id}`,
    body,
  );
  return response.data;
};
