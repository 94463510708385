import { GetRequest, Response } from '../../types/Comman';
// import { ResponseStudent } from '../../types/Student';
import AxiosInstance from '../../utils/AxiosConfig';

const baseURL = 'student';

export const getStudent = async (body: GetRequest): Promise<any> => {
  const { page, limit = 10, filter } = body;
  let url = `${baseURL}/get-all-student?page=${page}&limit=${limit}`;
  if (
    filter &&
    filter?.column?.length > 0 &&
    filter?.operation?.length > 0 &&
    filter?.value?.length > 0
  ) {
    console.log(filter);
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getArchiveStudent = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, filter } = body;
  let url = `${baseURL}/archived-student?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const restoreStudent = async (studentId: number): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/restore-student/${studentId}`,
  );
  return response.data;
};

export const archiveStudent = async (studentId: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-student/${studentId}`,
  );
  return response.data;
};

export const getSpecificStudents = async (
  dId?: number,
  mId?: number,
  sId?: number,
  divId?: number,
): Promise<any> => {
  const queryParams: any = {};

  if (dId !== undefined) queryParams.departmentId = dId;
  if (mId !== undefined) queryParams.mediumId = mId;
  if (sId !== undefined) queryParams.standardId = sId;
  if (divId !== undefined) queryParams.divisionId = divId;

  const queryString = new URLSearchParams(queryParams).toString();

  const response = await AxiosInstance.get(
    `${baseURL}/get-specificstudent?${queryString}`,
  );
  return response.data;
};

export const getStudentGRNumber = async (
  departmentId: number,
): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-grnumber/${departmentId}`,
  );
  return response.data;
};

export const createStudent = async (body: any): Promise<any> => {
  const response = await AxiosInstance.post(
    `${baseURL}/register-student`,
    body,
  );
  return response.data;
};

export const getStudentByID = async (studentId: number): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-student/${studentId}`,
  );
  return response.data;
};

export const updateStudent = async (body: {
  department_id?: number | null;
  id: any;
  gr_number?: string;
  gr_date?: string;
  departmentSearch?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  gender?: string;
  blood_group?: string;
  religion_id?: string;
  category_id?: string;
  subcaste_id?: string;
  nationality?: string;
  birth_date?: string;
  birth_place?: string;
  shift_id?: string;
  medium_id?: string;
  standard_id?: string;
  division_id?: string;
  roll_no?: string;
  previous_school?: string;
  address?: string;
  city?: string;
  state?: string;
  pincode?: string;
  country?: string;
  father_name?: string;
  father_phone?: string;
  father_email?: string;
  father_occupation?: string;
  mother_name?: string;
  mother_phone?: string;
  mother_email?: string;
  mother_occupation?: string;
  guardian_name?: string;
  guardian_phone?: string;
  guardian_email?: string;
  guardian_occupation?: string;
  guardian_relationship?: string;
  total_fee_amount: number;
  fee_category: number | null;
  fee_schedules: number | null;
  fee_amount_payable: number;
}): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/update-student/${body.id}`,
    body,
  );
  return response.data;
};
