import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../components/DataTable';
import { ReligionInterface } from '../../../types/Religion';
import { archiveReligion, getAllReligion } from '../../../controller';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { notifyError, notifySuccess } from '../../../components/CustomToast';
import {
  RELIGION_CREATE,
  RELIGION_UPDATE,
} from '../../../routes/settings/Religion';
import { useNavigate } from 'react-router-dom';

const List: React.FC = () => {
  const navigate = useNavigate();
  const [religions, setReligions] = useState<ReligionInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchReligions = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const { data, success } = await getAllReligion({ page, limit });
      if (success) {
        setReligions(data.religions);
        setTotalRows(data.totalReligions);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching religions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReligions(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchReligions(page, newPerPage);
  };

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Religion',
      'Are you sure you want to delete this religion?',
      async () => {
        try {
          const result = await archiveReligion(id);
          if (result.success) {
            // Remove the deleted religion from the list
            setReligions((prevReligions) =>
              prevReligions.filter((religion) => religion.id !== id),
            );
            notifySuccess('Religion deleted successfully!');
          } else {
            notifyError('Failed to delete religion');
          }
        } catch (error) {
          console.error('Error deleting religion:', error);
          notifyError('Error deleting religion');
        } finally {
          closeDialog();
        }
      },
    );
  };

  const columns: TableColumn<ReligionInterface>[] = [
    {
      name: 'No.',
      cell: (_row: ReligionInterface, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
    },
    {
      name: 'Religion',
      selector: (row: ReligionInterface) => row.name,
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: ReligionInterface) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row: ReligionInterface) => {
        const date = new Date(row.update_at ?? '');
        return row.update_at ? date.toLocaleDateString() : '';
      },
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              navigate(RELIGION_UPDATE.replace(':id', row?.id?.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  return (
    <>
      <div className="m-5 max-h-[550px] overflow-y-auto">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">Religions List</h2>
          <div className="flex justify-between">
            <button
              className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
              onClick={() => navigate(RELIGION_CREATE)}
            >
              Create
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={religions}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
