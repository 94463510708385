import { GetRequest } from '../../types/Comman';
import AxiosInstance from '../../utils/AxiosConfig';
import {
  FeeStructureInterface,
  getTotalFeeAmountInterface,
} from '../../types/FeeStructure';
const baseURL = 'feeStructure';

export const getTotalFeeAmount = async (
  body: getTotalFeeAmountInterface,
): Promise<any> => {
  const url = `${baseURL}/get-total-fee-amount`;
  const response = await AxiosInstance.post(url, body);
  return response.data;
};

// Create New Fee Structure
export const createFeeStructure = async (body: any): Promise<any> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-feeStructure`,
    body,
  );
  return response.data;
};

// Get All FeeStructure
export const getAllFeeStructures = async (body: GetRequest): Promise<any> => {
  const { page, limit } = body;
  const url = `${baseURL}/get-all-feeStructure?page=${page}&limit=${limit}`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getFeeStructureByID = async (Id: number): Promise<any> => {
  const response = await AxiosInstance.get(`${baseURL}/get-feeStructure/${Id}`);
  return response.data;
};

export const updateFeeStructure = async (
  body: FeeStructureInterface,
): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-feeStructure/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveFeeStructure = async (id: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-feeStructure/${id}`,
  );
  return response.data;
};
