import { RouteObject } from 'react-router-dom';
import List from '../../pages/payment/installment/List';
import AddUpdate from '../../pages/payment/installment/AddUpdate';

export const INSTALLMENT_TITLE = 'Installment';
export const INSTALLMENT_LIST = '/installment';
export const INSTALLMENT_CREATE = '/installment/add';
export const INSTALLMENT_UPDATE = '/installment/:id/edit';

const InstallmentRoutes: RouteObject[] = [
  {
    path: INSTALLMENT_LIST,
    element: <List />,
  },
  {
    path: INSTALLMENT_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: INSTALLMENT_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default InstallmentRoutes;
