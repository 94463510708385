import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/shift/List';
import AddUpdate from '../../pages/settings/shift/AddUpdate';

export const SHIFT_TITLE = 'Shift';
export const SHIFT_LIST = '/shift';
export const SHIFT_CREATE = '/shift/add';
export const SHIFT_UPDATE = '/shift/:id/edit';

const ShiftRoutes: RouteObject[] = [
  {
    path: SHIFT_LIST,
    element: <List />,
  },
  {
    path: SHIFT_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: SHIFT_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default ShiftRoutes;
