import { useNavigate } from 'react-router-dom';
const NotFound = () => {
  const navigate = useNavigate();

  const goToHomepage = () => {
    navigate('/');
  };

  return (
    <div className="border-5 mt-40 mx-auto max-w-2xl">
      <div className="flex justify-center">
        <div className="text-center">
          <div className="notfound">
            <div className="notfound-404">
              <h1 className="text-5xl font-sans">404</h1>
              <h5>Oops! The Page can&apos;t be found</h5>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
              onClick={goToHomepage}
            >
              Go To Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
