import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authUser } from '../../controller';
import { notifySuccess, notifyError } from '../../components/CustomToast';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  // Function For Handle Login
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const body = { user_name: username, password: password };
      const response = await authUser(body);
      if (response.success) {
        const { token, user } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('user_type', user.user_type);
        localStorage.setItem('user_id', user.id.toString());
        notifySuccess('Login Successful');
        navigate('/');
      } else {
        notifyError('Invalid username or password');
      }
    } catch (err) {
      notifyError('Invalid username or password');
      console.error('Login error:', err);
    }
  };

  return (
    <section
      className="bg-gray-50 dark:bg-gray-900 bg-cover bg-center"
      style={{
        backgroundImage:
          "url('https://www.sulinformacao.pt/wp-content/uploads/2014/06/EB-1%C2%BA-Ciclo-n%C2%BA4-Penha_Site-AEJD.jpg')",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="/"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-13 h-10 mr-2 filter invert brightness-0"
            src="https://static.vecteezy.com/system/resources/previews/021/013/584/non_2x/graduation-hat-on-transparent-background-free-png.png"
            alt="logo"
          />
          <p className="text-white">School Management</p>
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <div className="relative">
                <label className="block">
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                    placeholder=" "
                    autoComplete="off"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                    Your UserName
                  </span>
                </label>
              </div>
              <div className="relative">
                <label className="block">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    placeholder=" "
                    className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                    Password
                  </span>
                </label>
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                >
                  {showPassword ? (
                    <MdVisibilityOff size={24} />
                  ) : (
                    <MdVisibility size={24} />
                  )}
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <a
                  href="/"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
