import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/category/List';
import AddUpdate from '../../pages/settings/category/AddUpdate';

export const CATEGORY_TITLE = 'Category';
export const CATEGORY_LIST = '/category';
export const CATEGORY_CREATE = '/category/add';
export const CATEGORY_UPDATE = '/category/:id/edit';

const CategoryRoutes: RouteObject[] = [
  {
    path: CATEGORY_LIST,
    element: <List />,
  },
  {
    path: CATEGORY_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: CATEGORY_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default CategoryRoutes;
