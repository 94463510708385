import DataTable, { TableColumn } from 'react-data-table-component';

interface Props<T> {
  columns: TableColumn<T>[];
  data: T[];
  loading: boolean;
  totalRows: number;
  currentPage: number;
  perPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (newPerPage: number, page: number) => void;
  fixedHeader?: boolean;
  fixedHeaderScrollHeight?: string;
  customStyles?: object;
}

function CustomDataTable<T>({
  columns,
  data,
  loading,
  totalRows,
  perPage,
  onChangePage,
  onChangeRowsPerPage,
  fixedHeader = true,
  fixedHeaderScrollHeight = '460px',
  customStyles = {
    headRow: {
      style: {
        fontWeight: 'bold',
        color: '#3F51B5',
      },
    },
  },
}: Props<T>) {
  return (
    <DataTable
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={onChangePage}
      paginationPerPage={perPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      fixedHeader={fixedHeader}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
      highlightOnHover
      pointerOnHover
      customStyles={customStyles}
      striped
    />
  );
}

export default CustomDataTable;
