import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/standard/List';
import AddUpdate from '../../pages/settings/standard/AddUpdate';

export const STANDARD_TITLE = 'Standard';
export const STANDARD_LIST = '/standard';
export const STANDARD_CREATE = '/standard/add';
export const STANDARD_UPDATE = '/standard/:id/edit';

const StandardRoutes: RouteObject[] = [
  {
    path: STANDARD_LIST,
    element: <List />,
  },
  {
    path: STANDARD_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: STANDARD_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default StandardRoutes;
