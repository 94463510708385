import { GetRequest } from '../../types/Comman';
import { ResponseFeeSchedules } from '../../types/FeeSchedules';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'feeSchedules';

export const createFeeSchedules = async (
  body: ResponseFeeSchedules,
): Promise<any> => {
  const response = await AxiosInstance.post(
    `${baseURL}/create-feeSchedules`,
    body,
  );
  return response.data;
};

export const updateFeeSchedules = async (
  body: ResponseFeeSchedules,
): Promise<any> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-feeSchedules/${body.id}`,
    body,
  );
  return response.data;
};

export const getFeeSchedules = async (body: GetRequest): Promise<any> => {
  const { page, limit } = body;
  const url = `${baseURL}/get-all-feeSchedules?page=${page}&limit=${limit}`;
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const archiveFeeSchedules = async (id: number): Promise<any> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-feeSchedules/${id}`,
  );
  return response.data;
};

export const getFeeSchedulesByID = async (Id: number): Promise<any> => {
  const response = await AxiosInstance.get(`${baseURL}/get-feeSchedules/${Id}`);
  return response.data;
};
