import { RouteObject } from 'react-router-dom';
import List from '../../pages/organization/List';
import AddUpdate from '../../pages/organization/AddUpdate';
import View from '../../pages/organization/View';

export const ORGANIZATION_TITLE = 'Organization';
export const ORGANIZATION_LIST = '/organization';
export const ORGANIZATION_ARCHIVE_LIST = '/organization/archive';
export const ORGANIZATION_VIEW = '/organization/:id/view';
export const ORGANIZATION_CREATE = '/organization/add';
export const ORGANIZATION_UPDATE = '/organization/:id/edit';

const OrganizationRoutes: RouteObject[] = [
  {
    path: ORGANIZATION_LIST,
    element: <List isArchive={false} />,
  },
  {
    path: ORGANIZATION_ARCHIVE_LIST,
    element: <List isArchive={true} />,
  },
  {
    path: ORGANIZATION_VIEW,
    element: <View />,
  },
  {
    path: ORGANIZATION_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: ORGANIZATION_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default OrganizationRoutes;
