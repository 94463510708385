import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import type { LoadOptions } from 'react-select-async-paginate';
import { FeeStructureInterface } from '../../../../types/FeeStructure';
import { notifyError, notifySuccess } from '../../../../components/CustomToast';
import {
  createFeeStructure,
  updateFeeStructure,
  getDepartment,
  getMediumsByDepartment,
  getStandardByDepartment,
  getDivisionByStandard,
  getFeeStructureByID,
  getMediumByStandard,
} from '../../../../controller';
import {
  FEE_STRUCTURE_LIST,
  FEE_STRUCTURE_TITLE,
} from '../../../../routes/settings/FeeStructure';

interface DropdownOption {
  id: number;
  name: string;
}

interface Additional {
  page: number;
}

// Function For Fetch Department Data
const departmentdropdownloadOptions: LoadOptions<any, any, Additional> = async (
  inputValue,
  loadedOptions,
  additional = { page: 1 },
) => {
  const { page } = additional;
  const limit = 10;
  const filter = undefined;
  const { data } = await getDepartment({ page, limit, filter });

  return {
    options: data.departments.map((dep: DropdownOption) => ({
      label: dep.name,
      value: dep.id,
    })),
    hasMore: data.currentPage < data.totalPages,
    additional: {
      page: page + 1,
    },
  };
};

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [newFeeStructure, setNewFeeStructure] = useState<FeeStructureInterface>(
    {
      department_id: 0,
      medium_id: 0,
      standard_id: 0,
      division_id: 0,
      fee_amount: 0,
    },
  );

  const [loading, setLoading] = useState(false);
  const [departmentOptions, setDepartmentOptions] = useState<DropdownOption[]>(
    [],
  );

  // State For Standard Medium Data
  const [standardOptions, setStandardOptions] = useState<DropdownOption[]>([]);
  const [loadingStandards, setLoadingStandards] = useState(true);
  const [selectedStandard, setSelectedStandards] = useState<DropdownOption[]>(
    [],
  );

  // State For Manage Medium Data
  const [mediumOptions, setMediumOptions] = useState<DropdownOption[]>([]);
  const [selectedMedium, setSelectedMedium] = useState<DropdownOption[]>([]);
  const [loadingMediums, setLoadingMediums] = useState(true);

  // State For Manage Division Data
  const [divisionOptions, setDivisionOptions] = useState<DropdownOption[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<DropdownOption[]>(
    [],
  );
  const [loadingDivisions, setLoadingDivisions] = useState(true);

  useEffect(() => {
    if (isEdit && id) {
      const fetchFeeStructure = async () => {
        setLoading(true);
        try {
          const response = await getFeeStructureByID(Number(id));
          const { success, data } = response;
          if (success) {
            const feeStructure = data;
            setNewFeeStructure(feeStructure);
            await handleFetchInitialData(
              feeStructure.department_id,
              feeStructure.standard_id,
              feeStructure.medium_id,
              feeStructure.division_id,
            );
          } else {
            console.error('Failed to fetch FeeStructure:', response.error);
          }
        } catch (error) {
          console.error('Error fetching FeeStructure:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchFeeStructure();
    }
  }, [isEdit, id]);

  const handleFetchInitialData = async (
    departmentId: number,
    standardId: number,
    mediumId: number,
    divisionId: number,
  ) => {
    // Fetch and set the initial department
    const { data } = await getDepartment({
      page: 1,
      limit: 10,
      filter: undefined,
    });
    setDepartmentOptions(
      data.departments.map((dep: DropdownOption) => ({
        id: dep.id,
        name: dep.name,
      })),
    );
    // Fetch and set the initial standards based on department
    const standardResponse = await getStandardByDepartment(departmentId);
    console.log('stan res', standardResponse);
    setStandardOptions(
      standardResponse.map((stan: { id: number; name: string }) => ({
        id: stan.id,
        name: stan.name,
      })),
    );
    setSelectedStandards([
      {
        id: standardId,
        name: standardResponse.find(
          (stan: { id: number }) => stan.id === standardId,
        ).name,
      },
    ]);

    // Fetch and set the initial mediums based on department
    const mediumResponse = await getMediumsByDepartment(departmentId);
    setMediumOptions(
      mediumResponse.map((medi: { id: number; name: string }) => ({
        id: medi.id,
        name: medi.name,
      })),
    );
    setSelectedMedium([
      {
        id: mediumId,
        name: mediumResponse.find(
          (medi: { id: number }) => medi.id === mediumId,
        ).name,
      },
    ]);

    // Fetch and set the initial divisions based on standard
    const divisionResponse = await getDivisionByStandard(
      standardId,
      departmentId,
    );
    setDivisionOptions(
      divisionResponse.data.map((div: { id: number; name: string }) => ({
        id: div.id,
        name: div.name,
      })),
    );
    setSelectedDivision([
      {
        id: divisionId,
        name: divisionResponse.data.find(
          (div: { id: number }) => div.id === divisionId,
        ).name,
      },
    ]);
  };

  const handleDepartmentChange = (selectedOption: any) => {
    setDepartmentOptions(
      selectedOption
        ? [{ id: selectedOption.value, name: selectedOption.label }]
        : [],
    );
    setSelectedStandards([]);
    setSelectedMedium([]);
    setSelectedDivision([]);
    setLoadingDivisions(true);
  };

  const handleStandardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedStandards([{ id: id, name: name }]);
    setSelectedDivision([]);
  };

  const handleMediumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedMedium([{ id: id, name: name }]);
  };

  const handleDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedDivision([{ id: id, name: name }]);
  };

  // Latest Change For fetch standard
  useEffect(() => {
    if (departmentOptions.length > 0) {
      const fetchStandards = async () => {
        try {
          const response = await getStandardByDepartment(
            Number(departmentOptions[0].id),
          );
          setStandardOptions(
            response.data.standards.map(
              (stan: { id: number; name: string; is_active: boolean }) => ({
                id: stan.id,
                name: stan.name,
                is_active: stan.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching standards:', error);
        } finally {
          setLoadingStandards(false);
        }
      };

      fetchStandards();
    }
  }, [departmentOptions]);

  // Fetch Medium options based on Department selection
  useEffect(() => {
    if (departmentOptions.length > 0 && selectedStandard.length > 0) {
      const fetchMediums = async () => {
        try {
          const response = await getMediumByStandard(
            Number(departmentOptions[0].id),
            Number(selectedStandard[0].id),
          );
          setMediumOptions(
            response.data.map(
              (item: {
                mediums: { id: number; name: string; is_active: boolean };
              }) => ({
                id: item.mediums.id,
                name: item.mediums.name,
                is_active: item.mediums.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching medium:', error);
        } finally {
          setLoadingMediums(false);
        }
      };
      fetchMediums();
    }
  }, [departmentOptions, selectedStandard]);

  // Fetch Division options based on Standard selection
  useEffect(() => {
    if (selectedStandard.length > 0 && departmentOptions.length > 0) {
      const fetchDivisions = async () => {
        try {
          const response = await getDivisionByStandard(
            Number(departmentOptions[0].id),
            Number(selectedStandard[0].id),
          );

          if (response.success && response.data) {
            setDivisionOptions(
              response.data.map(
                (item: {
                  divisions: { id: number; name: string; is_active: boolean };
                }) => ({
                  id: item.divisions.id,
                  name: item.divisions.name,
                  is_active: item.divisions.is_active,
                }),
              ),
            );
          } else {
            console.error('Unexpected response structure:', response);
          }
        } catch (error) {
          console.error('Error fetching division:', error);
        } finally {
          setLoadingDivisions(false);
        }
      };
      fetchDivisions();
    }
  }, [selectedStandard, departmentOptions]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const updatedFeeStructure = {
        ...newFeeStructure,
        department_id: Number(departmentOptions[0].id),
        standard_id: Number(selectedStandard[0].id),
        medium_id: Number(selectedMedium[0].id),
        division_id: Number(selectedDivision[0].id),
      };

      let response;

      if (isEdit && id) {
        response = await updateFeeStructure({
          ...updatedFeeStructure,
          id: Number(id),
        });
        if (response.success) {
          notifySuccess('Fee Structure Updated Successfully');
          navigate(FEE_STRUCTURE_LIST);
        } else {
          notifyError('Error Updating Fee Structure');
          console.error('Error submitting form:', response.error);
        }
      } else {
        response = await createFeeStructure(updatedFeeStructure);
        if (response.success) {
          notifySuccess('Fee Structure Created Successfully');
          setNewFeeStructure({
            department_id: 0,
            medium_id: 0,
            standard_id: 0,
            division_id: 0,
            fee_amount: 0,
          });
          navigate(FEE_STRUCTURE_LIST);
        } else {
          console.error('Error submitting fee structure:', response.error);
          notifyError('Error Creating Fee Structure');
        }
      }
    } catch (error) {
      notifyError('Internal Server Error!');
      console.error('Error submitting form:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {FEE_STRUCTURE_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <AsyncPaginate
                      value={
                        departmentOptions.length > 0
                          ? {
                              label: departmentOptions[0].name,
                              value: departmentOptions[0].id,
                            }
                          : null
                      }
                      loadOptions={departmentdropdownloadOptions}
                      onChange={handleDepartmentChange}
                      additional={{
                        page: 1,
                      }}
                      className="w-full"
                      placeholder="Select Department"
                    />
                  </div>
                  <div className="relative w-1/2">
                    {loadingMediums ? (
                      <select
                        disabled
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      >
                        <option value="">Please Select Department</option>
                      </select>
                    ) : (
                      <select
                        id="medium"
                        name="medium"
                        value={
                          selectedMedium.length > 0 ? selectedMedium[0].id : ''
                        }
                        onChange={handleMediumChange}
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      >
                        <option value="">Select Medium</option>
                        {mediumOptions.map((medi) => (
                          <option key={medi.id} value={medi.id}>
                            {medi.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    {loadingStandards ? (
                      <select
                        disabled
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      >
                        <option value="">Please Select Department</option>
                      </select>
                    ) : (
                      <select
                        id="standard"
                        name="standard"
                        value={
                          selectedStandard.length > 0
                            ? selectedStandard[0].id
                            : ''
                        }
                        onChange={handleStandardChange}
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      >
                        <option value="">Select Standard</option>
                        {standardOptions.map((stan) => (
                          <option key={stan.id} value={stan.id}>
                            {stan.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="relative w-1/2">
                    {loadingDivisions ? (
                      <select
                        disabled
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      >
                        <option value="">Please Select Standard</option>
                      </select>
                    ) : (
                      <select
                        id="division"
                        name="division"
                        value={
                          selectedDivision.length > 0
                            ? selectedDivision[0].id
                            : ''
                        }
                        onChange={handleDivisionChange}
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      >
                        <option value="">Select Division</option>
                        {divisionOptions.map((div) => (
                          <option key={div.id} value={div.id}>
                            {div.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <input
                      type="number"
                      id="fee_amount"
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      value={newFeeStructure.fee_amount}
                      onChange={(e) =>
                        setNewFeeStructure((prev) => ({
                          ...prev,
                          fee_amount: Number(e.target.value),
                        }))
                      }
                      required
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Amount *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(FEE_STRUCTURE_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
