import React from 'react';
import './assets/styles/index.css';
import AppRoutes from './routes/AppRoutes';
import CustomToast from './components/CustomToast';

const App: React.FC = () => {
  return (
    <>
      <CustomToast />
      <AppRoutes />
    </>
  );
};

export default App;
