import React, { useEffect } from 'react'; // Update the path accordingly
import { useNavigate } from 'react-router-dom';

const LogOut: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Call the removeToken function to sign out the user
    // removeToken();

    // Redirect to the login page after signing out
    navigate('/login');
  });

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h2 className="text-2xl font-semibold mb-4">Sign Out</h2>
      <p className="text-lg">You have been signed out successfully.</p>
    </div>
  );
};

export default LogOut;
