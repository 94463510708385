import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/fee/feeCategory/List';
import AddUpdate from '../../pages/settings/fee/feeCategory/AddUpdate';

export const FEE_CATEGORY_TITLE = 'Fee Category';
export const FEE_CATEGORY_LIST = '/fee-category';
export const FEE_CATEGORY_CREATE = '/fee-category/add';
export const FEE_CATEGORY_UPDATE = '/fee-category/:id/edit';

const FeeRoutes: RouteObject[] = [
  {
    path: FEE_CATEGORY_LIST,
    element: <List />,
  },
  {
    path: FEE_CATEGORY_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: FEE_CATEGORY_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default FeeRoutes;
