import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getStudentByID } from '../../controller';
import { STUDENT_LIST, STUDENT_UPDATE } from '../../routes/student/Student';
import { ResponseStudent } from '../../types/Student';

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [student, setStudent] = useState<ResponseStudent | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentDetail = async () => {
      setLoading(true);
      try {
        const response = await getStudentByID(Number(id));
        const { success, data } = response;
        if (success) {
          setStudent(data);
        } else {
          console.error('Failed to fetch student detail:', response.error);
        }
      } catch (error) {
        console.error('Error fetching student detail:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetail();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!student) {
    return <div>Student not found</div>;
  }

  return (
    <div className="m-5">
      <div className="flex justify-between bg-white p-4 rounded-t shadow-md">
        <h2 className="text-gray-800 text-xl font-medium ml-6 my-1">
          Student Detail
        </h2>
        <div className="flex justify-between">
          <button
            className="bg-blue-500 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white mr-2"
            onClick={() =>
              navigate(STUDENT_UPDATE.replace(':id', student.id.toString()))
            }
          >
            Edit
          </button>
          <button
            className="bg-gray-500 cursor-pointer text-sm font-medium rounded p-2 hover:bg-gray-600 text-white"
            onClick={() => navigate(STUDENT_LIST)}
          >
            Back
          </button>
        </div>
      </div>
      <div className="border-b border-dashed border-gray-300"></div>
      <div className="bg-white p-4 rounded-b shadow-md flex">
        <div className="py-2 ml-6 space-y-5">
          <p className="font-normal text-slate-400">Student GR Number</p>
          <p className="font-normal text-slate-400">Student Full Name</p>
          <p className="font-normal text-slate-400">Student Department</p>
          <p className="font-normal text-slate-400">Student Religion</p>
          <p className="font-normal text-slate-400">Address</p>
          <p className="font-normal text-slate-400">Birth Date</p>
          <p className="font-normal text-slate-400">Birth Place</p>
          <p className="font-normal text-slate-400">Blood Group</p>
          <p className="font-normal text-slate-400">Category</p>
          <p className="font-normal text-slate-400">City</p>
          <p className="font-normal text-slate-400">Country</p>
          <p className="font-normal text-slate-400">Father&apos;s Name</p>
          <p className="font-normal text-slate-400">Father&apos;s Occupation</p>
          <p className="font-normal text-slate-400">Father&apos;s Phone</p>
          <p className="font-normal text-slate-400">Guardian&apos;s Name</p>
          <p className="font-normal text-slate-400">
            Guardian&apos;s Occupation
          </p>
          <p className="font-normal text-slate-400">Guardian&apos;s Phone</p>
          <p className="font-normal text-slate-400">Mother&apos;s Name</p>
          <p className="font-normal text-slate-400">Mother&apos;s Occupation</p>
          <p className="font-normal text-slate-400">Mother&apos;s Phone</p>
          <p className="font-normal text-slate-400">Nationality</p>
          <p className="font-normal text-slate-400">Pincode</p>
          <p className="font-normal text-slate-400">Previous School</p>
          <p className="font-normal text-slate-400">Shift</p>
          <p className="font-normal text-slate-400">Standard</p>
          <p className="font-normal text-slate-400">State</p>
          <p className="font-normal text-slate-400">SubCaste</p>
          <p className="font-normal text-slate-400">Total Fee</p>
          <p className="font-normal text-slate-400">Fee Category</p>
          <p className="font-normal text-slate-400">Fee Payable</p>
        </div>

        <div className="py-2 space-y-5 ml-52">
          {/* Student GR Number */}
          <p>{student.gr_number ? student.gr_number : '-'}</p>

          <p>
            {student.first_name} {student.middle_name} {student.last_name}
          </p>
          <p>
            {student.departmentDetails?.name
              ? student.departmentDetails?.name
              : '-'}
          </p>
          <p>
            {student.religionDetails?.name
              ? student.religionDetails?.name
              : '-'}
          </p>
          <p>{student.address ? student.address : '-'}</p>
          <p>{new Date(student.birth_date).toLocaleDateString()}</p>
          <p>{student.birth_place ? student.birth_place : '-'}</p>
          <p>{student.blood_group ? student.blood_group : '-'}</p>
          <p>
            {student.categoryDetails?.name
              ? student.categoryDetails?.name
              : '-'}
          </p>
          <p>{student.city ? student.city : '-'}</p>
          <p>{student.country ? student.country : '-'}</p>
          <p>{student.father_name ? student.father_name : '-'}</p>
          <p>{student.father_occupation ? student.father_occupation : '-'}</p>
          <p>{student.father_phone ? student.father_phone : '-'}</p>
          <p>{student.guardian_name ? student.guardian_name : '-'}</p>
          <p>
            {student.guardian_occupation ? student.guardian_occupation : '-'}
          </p>
          <p>{student.guardian_phone ? student.guardian_phone : '-'}</p>
          <p>{student.mother_name ? student.mother_name : '-'}</p>
          <p>{student.mother_occupation ? student.mother_occupation : '-'}</p>
          <p>{student.mother_phone ? student.mother_phone : '-'}</p>
          <p>{student.nationality ? student.nationality : '-'}</p>
          <p>{student.pincode ? student.pincode : '-'}</p>
          <p>{student.previous_school ? student.previous_school : '-'}</p>
          <p>{student.shiftDetails?.name ? student.shiftDetails?.name : '-'}</p>
          <p>
            {student.standardDetails?.name
              ? student.standardDetails?.name
              : '-'}
          </p>
          <p>{student.state ? student.state : '-'}</p>
          <p>
            {student.subCasteDetails?.name
              ? student.subCasteDetails?.name
              : '-'}
          </p>
          <p>{student.total_fee_amount ? student.total_fee_amount : '-'}</p>
          <p>
            {student.feeCategoryDetails?.name
              ? student.feeCategoryDetails?.name
              : '-'}
          </p>
          <p>{student.fee_amount_payable ? student.fee_amount_payable : '-'}</p>
        </div>
      </div>
    </div>
  );
};

export default View;
