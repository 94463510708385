import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

interface FilterCriteria {
  column: string[];
  operation: string[];
  value: string[];
}

interface CustomFilterProps {
  columns: { value: string; label: string }[];
  operations: { value: string; label: string }[];
  onApplyFilter: (filter: FilterCriteria) => void;
}

const CustomFilter: React.FC<CustomFilterProps> = ({
  columns,
  operations,
  onApplyFilter,
}) => {
  const [filter, setFilter] = useState<FilterCriteria>({
    column: [columns[0].value],
    operation: [operations[0].value],
    value: [''],
  });

  const handleInputChange = (newValue: any, actionMeta: any) => {
    if (
      actionMeta.action === 'select-option' ||
      actionMeta.action === 'remove-value'
    ) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        column: newValue ? newValue.map((option: any) => option.value) : [],
        operation: newValue
          ? newValue.map(
              (_option: any, index: number) =>
                prevFilter.operation[index] || operations[0].value,
            )
          : [],
        value: newValue
          ? newValue.map(
              (_option: any, index: number) => prevFilter.value[index] || '',
            )
          : [],
      }));
    }
  };

  const handleOperationChange = (value: string, index: number) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      operation: prevFilter.operation.map((op, i) =>
        i === index ? value : op,
      ),
    }));
  };

  const handleInputChangeValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      value: prevFilter.value.map((v, i) => (i === index ? value : v)),
    }));
  };

  const handleApplyFilter = () => {
    onApplyFilter(filter);
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu is above other elements
    }),
  };

  return (
    <div className="p-4 bg-white mb-3 rounded shadow-lg">
      <div className="mb-2 flex">
        <div className="flex-1">
          <AsyncPaginate
            value={columns.filter((col) => filter.column.includes(col.value))}
            loadOptions={(inputValue, loadedOptions) =>
              Promise.resolve({
                options: columns.filter(
                  (col) =>
                    col.label
                      .toLowerCase()
                      .includes(inputValue.toLowerCase()) &&
                    !loadedOptions.some(
                      (opt) =>
                        typeof opt === 'object' &&
                        opt !== null &&
                        'value' in opt &&
                        opt.value === col.value,
                    ),
                ),
              })
            }
            onChange={handleInputChange}
            isMulti
            classNamePrefix="react-select"
            className="w-full"
            styles={customStyles}
          />
        </div>
        <button
          onClick={handleApplyFilter}
          className="bg-blue-500 ml-2 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white"
        >
          Apply
        </button>
      </div>

      <div className="m-2 row">
        {filter.column.map((col, index) => {
          const columnLabel = columns.find(
            (column) => column.value === col,
          )?.label;
          return (
            <div key={col} className="my-3 flex items-center col-6 col-md-6">
              <span className="text-sm bg-gray-200 p-1 rounded-md font-medium mr-2">
                {columnLabel}:
              </span>
              <select
                value={filter.operation[index]}
                onChange={(e) => handleOperationChange(e.target.value, index)}
                className="ml-2 border rounded p-1 text-sm"
              >
                {operations.map((op) => (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                ))}
              </select>
              <input
                type="text"
                value={filter.value[index]}
                onChange={(e) => handleInputChangeValue(e, index)}
                className="ml-2 border rounded p-1 text-sm"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomFilter;
