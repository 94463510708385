import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../components/DataTable';
import { ResponseCategory } from '../../../types/Category';
import { deleteCategory, getAllCategory } from '../../../controller';
import { notifyError, notifySuccess } from '../../../components/CustomToast';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import {
  CATEGORY_CREATE,
  CATEGORY_UPDATE,
} from '../../../routes/settings/Category';

const List: React.FC = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState<ResponseCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchCategories = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const { success, data } = await getAllCategory({ page, limit });
      if (success) {
        setCategories(data.categories);
        setTotalRows(data.totalCategory);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchCategories(page, newPerPage);
  };

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Category',
      'Are you sure you want to delete this category?',
      async () => {
        try {
          const { success } = await deleteCategory(id);
          if (!success) {
            notifyError('Failed to delete category');
            return;
          }
          setCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id),
          );
          notifySuccess('Category deleted successfully!');
          return;
        } catch (error) {
          console.error('Error deleting category:', error);
          notifyError('Error deleting category');
        } finally {
          closeDialog();
        }
      },
    );
  };

  const columns: TableColumn<ResponseCategory>[] = [
    {
      name: 'No.',
      cell: (_row: ResponseCategory, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row: ResponseCategory) => row.name,
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: ResponseCategory) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row: ResponseCategory) => {
        const date = new Date(row.update_at ?? '');
        return row.update_at ? date.toLocaleDateString() : '';
      },
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              row.id &&
              navigate(CATEGORY_UPDATE.replace(':id', row.id.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => row.id && handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  return (
    <>
      <div className="m-5 max-h-[550px] overflow-y-auto">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            Categories List
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
              onClick={() => navigate(CATEGORY_CREATE)}
            >
              Create
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={categories}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
