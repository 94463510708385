import { RouteObject, useRoutes } from 'react-router-dom';
import Login from '../pages/auth/Login';
import AuthLayout from '../components/Layout/AuthLayout';
import LogOut from '../pages/auth/LogOut';
import NotFound from '../components/NotFound';
import MainLayout from '../components/Layout/Layout';
// import Dashboard from '../pages/general/Dashboard';
import StudentList from '../pages/student/List';

import Branch from './branch/Branch';
import Organization from './organization/Organization';
import Student from './student/Student';
import FeeCollection from './feeCollection/FeeCollection';
import FeeSchedules from './payment/FeeSchedules';
import Installment from './payment/Installment';

import Category from './settings/Category';
import Department from './settings/Department';
import Standard from './settings/Standard';
import Subcast from './settings/Subcaste';
import Medium from './settings/Medium';
import Religion from './settings/Religion';
import Shift from './settings/Shift';
import Division from './settings/Division';
import FeeStructure from './settings/FeeStructure';
import FeeCategory from './settings/FeeCategory';
import Profile from '../pages/auth/Profile';

export const LOGOUT = '/logout';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const NOT_FOUND = '*';

const authRoutes: RouteObject[] = [{ path: LOGIN, element: <Login /> }];

const mainRoutes: RouteObject[] = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: PROFILE,
        element: <Profile />,
      },
      ...Organization,
      ...Branch,
      ...Student,
      ...Category,
      ...Department,
      ...Standard,
      ...Subcast,
      ...Division,
      ...Medium,
      ...Religion,
      ...Shift,
      ...FeeStructure,
      ...FeeCategory,
      ...FeeCollection,
      ...FeeSchedules,
      ...Installment,
      // { index: true, element: <Dashboard /> },
      { index: true, element: <StudentList /> },
    ],
  },
];

const routes: RouteObject[] = [
  { path: LOGIN, element: <AuthLayout />, children: authRoutes },
  { path: LOGOUT, element: <LogOut /> },
  ...mainRoutes,
  { path: NOT_FOUND, element: <NotFound /> },
];

/**
 * AppRoutes component manages the application's routing logic.
 * It defines routes for authentication, main application pages, and handles unknown paths.
 * @returns {React.ReactElement} The rendered React element for the routes.
 */
const AppRoutes = () => {
  const element = useRoutes(routes);
  return <div>{element}</div>;
};
export default AppRoutes;
