import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import type { LoadOptions } from 'react-select-async-paginate';
import { BRANCH_LIST, BRANCH_TITLE } from '../../routes/branch/Branch';
import { getOrganization } from '../../controller';
import { createBranch, getBranchByID, updateBranch } from '../../controller';
import { Branch } from '../../types/Branch';
import { notifyError, notifySuccess } from '../../components/CustomToast';
import axios from 'axios';

interface OrganizationInterface {
  id: number;
  name: string;
}

interface Additional {
  page: number;
}

const loadOptions: LoadOptions<any, any, Additional> = async (
  inputValue,
  loadedOptions,
  additional = { page: 1 },
) => {
  const { page } = additional;
  const limit = 10;
  const filter = undefined;
  const { data } = await getOrganization({ page, limit, filter });
  return {
    options: data.organizations.map((org: OrganizationInterface) => ({
      label: org.name,
      value: org.id,
    })),
    hasMore: data.currentPage < data.totalPages,
    additional: {
      page: page + 1,
    },
  };
};

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<Branch>({
    id: null,
    name: '',
    address: '',
    email: '',
    phone: '',
    established_date: '',
    is_active: true,
    organization_id: null,
    organization: { id: null, name: '' },
    create_at: '',
    update_at: '',
    delete_at: null,
  });
  const [loading, setLoading] = useState(false);
  const organizationInclude = 'organization';

  useEffect(() => {
    if (isEdit && id) {
      const fetchBranch = async () => {
        setLoading(true);
        try {
          const { success, data, message } = await getBranchByID(
            Number(id),
            organizationInclude,
          );
          if (success) {
            // Ensure established_date is formatted as YYYY-MM-DD
            if (data.established_date) {
              const formattedDate = new Date(data.established_date)
                .toISOString()
                .split('T')[0];
              data.established_date = formattedDate;
            }
            setFormData(data);
          } else {
            console.error('Failed to fetch branch:', message);
          }
        } catch (error) {
          console.error('Error fetching branch:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchBranch();
    }
  }, [isEdit, id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleOrganizationChange = (selectedOption: any) => {
    setFormData((prev) => ({
      ...prev,
      organization_id: selectedOption ? selectedOption.value : null,
      organization: {
        id: selectedOption ? selectedOption.value : null,
        name: selectedOption ? selectedOption.label : '',
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const response = await updateBranch(formData);
        if (response.success) {
          navigate(BRANCH_LIST);
          notifySuccess(response.message);
        }
        return;
      } else {
        const response = await createBranch(formData);
        if (response.success) {
          notifySuccess(response.message);
          navigate(BRANCH_LIST);
        }
        return;
      }
    } catch (error: any) {
      notifyError(error.response.data.message);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {BRANCH_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder=" " // Note the space
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Branch Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Branch Address *
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Email *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Phone *
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="established_date"
                        value={formData.established_date}
                        onChange={handleInputChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                        onFocus={(e) => (e.target.type = 'date')}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            e.target.type = 'text';
                          }
                        }}
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Established Date *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <AsyncPaginate
                      value={{
                        label:
                          formData?.organization?.name || 'Select Organization',
                        value: formData?.organization?.id,
                      }}
                      loadOptions={loadOptions}
                      onChange={handleOrganizationChange}
                      additional={{
                        page: 1,
                      }}
                      className="w-full"
                      placeholder="Select Organization"
                    />
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleInputChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(BRANCH_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
