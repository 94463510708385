import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../components/DataTable';
import { archiveStandart, getStandard } from '../../../controller';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { notifyError, notifySuccess } from '../../../components/CustomToast';
import {
  STANDARD_CREATE,
  STANDARD_UPDATE,
} from '../../../routes/settings/Standard';
import { useNavigate } from 'react-router-dom';

interface StandardInterface {
  id: number;
  name: string;
  is_active: boolean;
  create_at?: Date;
  update_at?: Date;
  delete_at: string | null;
}

const List: React.FC = () => {
  const navigate = useNavigate();
  const [standards, setStandards] = useState<StandardInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchStandards = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response = await getStandard({ page, limit });
      const { success, data } = response;
      if (success) {
        setStandards(data.standards);
        setTotalRows(data.totalStandards);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching standards:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStandards(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchStandards(page, newPerPage);
  };

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Standard',
      'Are you sure you want to delete this standard?',
      async () => {
        try {
          const response = await archiveStandart(id);
          if (response.success) {
            // Remove the deleted standard from the list
            setStandards((prevStandards) =>
              prevStandards.filter((standard) => standard.id !== id),
            );
            notifySuccess('Standard deleted successfully!');
          } else {
            notifyError('Failed to delete standard');
          }
        } catch (error) {
          console.error('Error deleting standard:', error);
          notifyError('Error deleting standard');
        } finally {
          closeDialog();
        }
      },
    );
  };

  const columns: TableColumn<StandardInterface>[] = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Standard',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: StandardInterface) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: (row: StandardInterface) => {
        const date = new Date(row.update_at ?? '');
        return row.update_at ? date.toLocaleDateString() : '';
      },
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              navigate(STANDARD_UPDATE.replace(':id', row?.id?.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">Standard List</h2>
          <button
            onClick={() => navigate(STANDARD_CREATE)}
            className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
          >
            Create
          </button>
        </div>
        <CustomDataTable
          columns={columns}
          data={standards}
          loading={loading}
          totalRows={totalRows}
          currentPage={currentPage}
          perPage={perPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
