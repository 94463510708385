import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { SingleValue, components } from 'react-select';
import {
  createDepartment,
  // divisionsAssignToDepartment,
  getAllBranch,
  getDepartmentID,
  // getDivision,
  // getDivisionByDepartment,
  // getMediumsByDepartment,
  // getSearchMedium,
  getStandard,
  getStandardByDepartment,
  // assignMedium,
  standardsAssign,
  updateDepartment,
} from '../../../controller';
import { ResponseDepartment } from '../../../types/Department';
import { DEPARTMENT_LIST } from '../../../routes/settings/Department';
import { notifyError, notifySuccess } from '../../../components/CustomToast';
import axios from 'axios';

interface Branch {
  id: number;
  name: string;
}

interface StandardInterface {
  value: number;
  label: string;
}

// interface MediumInterface {
//   value: number;
//   label: string;
// }

// interface DivisionInterface {
//   value: number;
//   label: string;
// }

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [formData, setFormData] = useState<ResponseDepartment>({
    id: null,
    name: '',
    established_date: '',
    branch_id: '',
    delete_at: null,
    is_active: true,
  });
  const [loading, setLoading] = useState(false);

  const [selectedStandards, setSelectedStandards] = useState<
    StandardInterface[]
  >([]);
  const [assignedStandards, setAssignedStandards] = useState<
    StandardInterface[]
  >([]);

  // const [selectedMediums, setSelectedMediums] = useState<MediumInterface[]>([]);
  // const [assignedMediums, setAssignedMediums] = useState<MediumInterface[]>([]);

  // const [selectedDivisions, setSelectedDivisions] = useState<
  //   DivisionInterface[]
  // >([]);
  // const [assignedDivisions, setAssignedDivisions] = useState<
  //   DivisionInterface[]
  // >([]);
  const fetchAssignedEntities = async () => {
    try {
      const [standardsResponse] = await Promise.all([
        getStandardByDepartment(Number(id)),
        // getMediumsByDepartment(Number(id)),
        // getDivisionByDepartment(Number(id)),
      ]);
      const standards = standardsResponse?.data?.standards?.map(
        (standard: any) => ({
          value: standard.id,
          label: standard.name,
        }),
      );
      // const mediums = mediumsResponse.map((medium: any) => ({
      //   value: medium.id,
      //   label: medium.name,
      // }));

      // const divisions = divisionsResponse.map((div: any) => ({
      //   value: div.id,
      //   label: div.name,
      // }));

      setAssignedStandards(standards);
      setSelectedStandards(standards);
      // setAssignedMediums(mediums);
      // setSelectedMediums(mediums);
      // setAssignedDivisions(divisions);
      // setSelectedDivisions(divisions);
    } catch (error) {
      console.error('Error fetching assigned entities:', error);
    }
  };

  const branchloadOptions = async ({ page }: any) => {
    const { data } = await getAllBranch({ page: 1, limit: 10 });
    return {
      options: data.branches.map((branch: Branch) => ({
        label: branch.name,
        value: branch.id,
      })),
      hasMore: data.currentPage < data.totalPages,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    if (isEdit && id) {
      const fetchDepartment = async () => {
        setLoading(true);
        try {
          const { success, data, message } = await getDepartmentID(Number(id));
          if (success) {
            // Ensure established_date is formatted as YYYY-MM-DD
            if (data.established_date) {
              const formattedDate = new Date(data.established_date)
                .toISOString()
                .split('T')[0];
              data.established_date = formattedDate;
            }
            setFormData(data);
          } else {
            console.error('Failed to fetch department:', message);
          }
        } catch (error) {
          console.error('Error fetching department:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDepartment();
      fetchAssignedEntities();
    }
  }, [isEdit, id, token]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      branch_id: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const { message, success } = await updateDepartment(formData);
        if (success) {
          navigate(DEPARTMENT_LIST);
          notifySuccess(message);
          return;
        }
        notifyError('Error Updating Department');
        console.error('Error submitting form:', message);
        return;
      } else {
        const { success, message } = await createDepartment(formData);
        if (success) {
          notifySuccess(message);
          navigate(DEPARTMENT_LIST);
          return;
        }
        notifyError('Error Creating Department');
        console.error('Error submitting form:', message);
        return;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notifyError(error.response.data.message);
        } else {
          notifyError('Internal Server Error');
        }
      } else {
        notifyError('An unexpected error occurred');
      }
      console.error('Error submitting form:', error);
    }
  };

  const standardloadOptions: LoadOptions<
    StandardInterface,
    any,
    { page: number }
  > = async (
    search: string,
    loadedOptions: readonly StandardInterface[],
    additional: { page: number } = { page: 1 },
  ) => {
    const page = additional.page;
    const limit = 10;
    try {
      const response = await getStandard({ page, limit, search: search });
      const { success, data } = response;
      if (success) {
        return {
          options: data.standards.map((standard: any) => ({
            value: standard.id,
            label: standard.name,
          })),
          hasMore: data.totalPages > additional.page,
          additional: {
            page: additional.page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: additional.page + 1,
          },
        };
      }
    } catch (error) {
      console.error('Error fetching standards:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      };
    }
  };

  // const mediumloadOptions: LoadOptions<
  //   MediumInterface,
  //   any,
  //   { page: number }
  // > = async (
  //   search: string,
  //   loadedOptions: readonly MediumInterface[],
  //   additional: { page: number } = { page: 1 },
  // ) => {
  //   try {
  //     const page = additional.page;
  //     const limit = 10;
  //     const response = await getSearchMedium({ page, limit, search });
  //     if (response.success) {
  //       return {
  //         options: response.mediums.map((medium: any) => ({
  //           value: medium.id,
  //           label: medium.name,
  //         })),
  //         hasMore: response.totalPages > additional.page,
  //         additional: {
  //           page: additional.page + 1,
  //         },
  //       };
  //     } else {
  //       return {
  //         options: [],
  //         hasMore: false,
  //         additional: {
  //           page: additional.page + 1,
  //         },
  //       };
  //     }
  //   } catch (error) {
  //     console.error('Error fetching mediums:', error);
  //     return {
  //       options: [],
  //       hasMore: false,
  //       additional: {
  //         page: additional.page + 1,
  //       },
  //     };
  //   }
  // };

  // const divisionloadOptions: LoadOptions<
  //   DivisionInterface,
  //   any,
  //   { page: number }
  // > = async (
  //   search: string,
  //   loadedOptions: readonly DivisionInterface[],
  //   additional: { page: number } = { page: 1 },
  // ) => {
  //   try {
  //     const page = additional.page;
  //     const limit = 10;
  //     const response = await getDivision({ page, limit, search });
  //     if (response.success) {
  //       return {
  //         options: response.divisions.map((division: any) => ({
  //           value: division.id,
  //           label: division.name,
  //         })),
  //         hasMore: response.totalPages > additional.page,
  //         additional: {
  //           page: additional.page + 1,
  //         },
  //       };
  //     } else {
  //       return {
  //         options: [],
  //         hasMore: false,
  //         additional: {
  //           page: additional.page + 1,
  //         },
  //       };
  //     }
  //   } catch (error) {
  //     console.error('Error fetching division:', error);
  //     return {
  //       options: [],
  //       hasMore: false,
  //       additional: {
  //         page: additional.page + 1,
  //       },
  //     };
  //   }
  // };

  const assignStandards = async () => {
    try {
      const data = {
        department_id: Number(id),
        standard_ids: selectedStandards.map((standard) => standard.value),
      };
      const response = await standardsAssign(data);
      if (response.success) {
        notifySuccess('Standards assigned successfully!');
        fetchAssignedEntities();
        setSelectedStandards([]);
      } else {
        notifyError('Failed to assign standards');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notifyError(error.response.data.message);
        } else {
          notifyError('Internal Server Error');
        }
      } else {
        notifyError('An unexpected error occurred');
      }
      console.error('Error assigning standards:', error);
    }
  };

  // const assignMediums = async () => {
  //   try {
  //     const data = {
  //       department_id: Number(id),
  //       medium_ids: selectedMediums.map((medium) => medium.value),
  //     };
  //     const response = await assignMedium(data);
  //     if (response.success) {
  //       notifySuccess('Medium assigned successfully!');
  //       fetchAssignedEntities();
  //       setSelectedMediums([]);
  //     } else {
  //       notifyError('Failed to assign Medium');
  //     }
  //   } catch (error) {
  //     console.error('Error assigning mediums:', error);
  //     notifyError('Error assigning mediums');
  //   }
  // };

  // const assignDivisions = async () => {
  //   try {
  //     const data = {
  //       department_id: Number(id),
  //       division_ids: selectedDivisions.map((division) => division.value),
  //     };
  //     const response = await divisionsAssignToDepartment(data);
  //     if (response.success) {
  //       notifySuccess('Divisions assigned successfully!');
  //       fetchAssignedEntities();
  //       setSelectedDivisions([]);
  //     } else {
  //       notifyError('Failed to assign Division');
  //     }
  //   } catch (error) {
  //     console.error('Error assigning Divisions:', error);
  //     notifyError('Error assigning Divisions');
  //   }
  // };

  const hasChanges = (selected: any[], assigned: any[]) => {
    if (selected.length !== assigned.length) return true;
    const selectedValues = selected
      .map((option: { value: any }) => option.value)
      .sort();
    const assignedValues = assigned
      .map((option: { value: any }) => option.value)
      .sort();
    return !selectedValues.every(
      (value: any, index: number) => value === assignedValues[index],
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col m-1 px-3">
      <div className="py-1 flex justify-end">
        <button
          type="button"
          className="bg-violet-700 text-white py-1 px-3 rounded hover:bg-violet-800"
          onClick={() => navigate(DEPARTMENT_LIST)}
        >
          Back
        </button>
      </div>
      <div className="bg-white rounded">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit Department' : 'Create New Department'}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Department Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="established_date"
                        value={formData.established_date}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                        onFocus={(e) => (e.target.type = 'date')}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            e.target.type = 'text';
                          }
                        }}
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Established Date *
                      </span>
                    </label>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                  <div className="relative w-1/2">
                    {!isEdit && (
                      <>
                        <AsyncPaginate
                          loadOptions={branchloadOptions}
                          additional={{
                            page: 1,
                          }}
                          placeholder="Select Branch"
                          onChange={(
                            newValue: SingleValue<{ value: string }>,
                          ) => {
                            setFormData({
                              ...formData,
                              branch_id: newValue ? newValue.value : '',
                            });
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(DEPARTMENT_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>

            {isEdit && (
              <>
                <div className="bg-gray-100 p-5 rounded mt-5">
                  <h2 className="text-base font-medium text-gray-900 mb-2">
                    Select Standards For Assign Department
                  </h2>
                  <div className="flex justify-between">
                    <div className="w-9/12">
                      <AsyncPaginate
                        isMulti
                        required
                        value={selectedStandards}
                        loadOptions={standardloadOptions}
                        additional={{ page: 1 }}
                        onChange={(value) =>
                          setSelectedStandards(value as StandardInterface[])
                        }
                        components={{
                          MultiValue: components.MultiValue,
                          Option: components.Option,
                        }}
                        closeMenuOnSelect={false}
                      />
                    </div>
                    <button
                      className={`py-1 px-3 rounded-md ml-2 ${hasChanges(selectedStandards, assignedStandards) ? 'bg-blue-500 text-white hover:bg-blue-600 cursor-pointer' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
                      onClick={assignStandards}
                      disabled={
                        !hasChanges(selectedStandards, assignedStandards)
                      }
                    >
                      Save Change
                    </button>
                  </div>
                </div>
                {/* <div className="bg-gray-100 p-5 rounded mt-5">
                  <h2 className="text-base font-medium text-gray-900 mb-2">
                    Select Medium For Assign Department
                  </h2>
                  <div className="flex justify-between">
                    <div className="w-9/12">
                      <AsyncPaginate
                        isMulti
                        required
                        value={selectedMediums}
                        loadOptions={mediumloadOptions}
                        additional={{ page: 1 }}
                        onChange={(value) =>
                          setSelectedMediums(value as MediumInterface[])
                        }
                        components={{
                          MultiValue: components.MultiValue,
                          Option: components.Option,
                        }}
                        closeMenuOnSelect={false}
                      />
                    </div>
                    <button
                      className={`py-1 px-3 rounded-md ml-2 ${hasChanges(selectedMediums, assignedMediums) ? 'bg-blue-500 text-white hover:bg-blue-600 cursor-pointer' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
                      onClick={assignMediums}
                      disabled={!hasChanges(selectedMediums, assignedMediums)}
                    >
                      Save Change
                    </button>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUpdate;
