import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToast: React.FC = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
  />
);

export const notifySuccess = (message: string) => {
  toast.success(message, {
    position: 'bottom-right',
  });
};

export const notifyError = (message: string) => {
  toast.error(message, {
    position: 'bottom-right',
  });
};

export const notifyWarning = (message: string) => {
  toast.warn(message, {
    position: 'bottom-right',
  });
};

export const notifyInfo = (message: string) => {
  toast.info(message, {
    position: 'bottom-right',
  });
};

export default CustomToast;
