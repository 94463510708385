import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import type { LoadOptions } from 'react-select-async-paginate';
import {
  getCategory,
  getStandardByDepartment,
  getStudentGRNumber,
  getSubCaste,
  getDepartment,
  getDivisionByStandard,
  getStudentByID,
  updateStudent,
  getReligion,
  getAllShift,
  getFeeCategory,
  getTotalFeeAmount,
  getFeeSchedules,
  createStudent,
  getMediumByStandard,
} from '../../controller';
import { ResponseStudent } from '../../types/Student';
import { STUDENT_LIST } from '../../routes/student/Student';
import { notifyError, notifySuccess } from '../../components/CustomToast';

interface DropdownOption {
  id: number;
  name: string;
  is_active?: boolean;
  percentage?: number;
}

interface Additional {
  page: number;
}

// Function For Fetch Department Data
const departmentdropdownloadOptions: LoadOptions<any, any, Additional> = async (
  inputValue,
  loadedOptions,
  additional = { page: 1 },
) => {
  const { page } = additional;
  const { data } = await getDepartment({
    page,
    limit: 10,
  });
  return {
    options: data.departments.map((dep: DropdownOption) => ({
      label: dep.name,
      value: dep.id,
    })),
    hasMore: data.currentPage < data.totalPages,
    additional: {
      page: page + 1,
    },
  };
};

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();

  // State For Manage Religion, Categories, And Subcaste And FeeCategory Data.
  const [religions, setReligions] = useState<DropdownOption[]>([]);
  const [categories, setCategories] = useState<DropdownOption[]>([]);
  const [subCastes, setSubCastes] = useState<DropdownOption[]>([]);
  const [feeCategory, setFeeCategory] = useState<DropdownOption[]>([]);
  const [feeSchedule, setFeeSchedule] = useState<DropdownOption[]>([]);
  const [shift, setShift] = useState<DropdownOption[]>([]);

  // State For Standard Medium Data
  const [standardOptions, setStandardOptions] = useState<DropdownOption[]>([]);
  const [loadingStandards, setLoadingStandards] = useState(true);

  // State For Manage Medium Data
  const [mediumOptions, setMediumOptions] = useState<DropdownOption[]>([]);
  const [loadingMediums, setLoadingMediums] = useState(true);

  // State For Manage Division Data
  const [divisionOptions, setDivisionOptions] = useState<DropdownOption[]>([]);
  const [loadingDivisions, setLoadingDivisions] = useState(true);

  const [formData, setFormData] = useState<ResponseStudent>({
    id: 0,
    gr_number: '',
    gr_date: '',
    department_id: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    gender: '',
    blood_group: '',
    religion_id: '',
    category_id: '',
    subcaste_id: '',
    nationality: 'Indian',
    birth_date: '',
    birth_place: '',
    shift_id: '',
    medium_id: '',
    standard_id: '',
    division_id: '',
    roll_no: '',
    previous_school: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    country: 'India',
    father_name: '',
    father_phone: '',
    father_email: '',
    father_occupation: '',
    mother_name: '',
    mother_phone: '',
    mother_email: '',
    mother_occupation: '',
    guardian_name: '',
    guardian_phone: '',
    guardian_email: '',
    guardian_occupation: '',
    guardian_relationship: '',
    total_fee_amount: 0,
    fee_category: { id: 0, name: '', percentage: 0 },
    fee_schedules: { id: 0, name: '' },
    fee_amount_payable: 0,
  });

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Fetch Religion, Categories, and subCaste and feeCategory dropdown data
  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const [
          religionData,
          categoryData,
          subCasteData,
          feeCategoryData,
          feeSchedulesData,
          shiftData,
        ] = await Promise.all([
          getReligion(),
          getCategory(),
          getSubCaste(),
          getFeeCategory({ page: 1, limit: 20 }),
          getFeeSchedules({ page: 1, limit: 20 }),
          getAllShift({ page: 1, limit: 20 }),
        ]);

        if (religionData.success)
          setReligions(
            religionData.data.religions.map(
              (r: { id: number; name: string; is_active: boolean }) => ({
                id: r.id,
                name: r.name,
                is_active: r.is_active,
              }),
            ),
          );
        if (categoryData.success)
          setCategories(
            categoryData.data.categories.map(
              (c: { id: number; name: string; is_active: boolean }) => ({
                id: c.id,
                name: c.name,
                is_active: c.is_active,
              }),
            ),
          );
        if (subCasteData.success)
          setSubCastes(
            subCasteData.data.subcastes.map(
              (s: { id: number; name: string; is_active: boolean }) => ({
                id: s.id,
                name: s.name,
                is_active: s.is_active,
              }),
            ),
          );
        if (feeCategoryData.success)
          setFeeCategory(
            feeCategoryData.data.feeCategories.map(
              (fc: {
                id: number;
                name: string;
                percentage: number;
                is_active: boolean;
              }) => ({
                id: fc.id,
                name: fc.name,
                percentage: fc.percentage,
                is_active: fc.is_active,
              }),
            ),
          );
        if (feeSchedulesData.success)
          setFeeSchedule(
            feeSchedulesData.data.feeSchedules.map(
              (fs: { id: number; name: string; is_active: boolean }) => ({
                id: fs.id,
                name: fs.name,
                is_active: fs.is_active,
              }),
            ),
          );
        if (shiftData.success)
          setShift(
            shiftData.data.shifts.map(
              (shift: { id: number; name: string; is_active: boolean }) => ({
                id: shift.id,
                name: shift.name,
                is_active: shift.is_active,
              }),
            ),
          );
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, [token]);

  // Fetch GR Number based on Department selection
  useEffect(() => {
    if (!isEdit && !id) {
      if (formData.department_id?.id) {
        const fetchgr = async () => {
          try {
            const response = await getStudentGRNumber(
              Number(formData?.department_id?.id),
            );
            setFormData((prevData) => ({
              ...prevData,
              gr_number: response.data.gr_number,
            }));
          } catch (error) {
            console.error('Error fetching gr:', error);
          }
        };
        fetchgr();
      }
    }
  }, [formData.department_id, isEdit, id]);

  // Fetch Standard options based on Department selection
  useEffect(() => {
    if (formData.department_id?.id) {
      const fetchStandards = async () => {
        try {
          const response = await getStandardByDepartment(
            Number(formData.department_id?.id),
          );
          setStandardOptions(
            response.data.standards.map(
              (stan: { id: number; name: string; is_active: boolean }) => ({
                id: stan.id,
                name: stan.name,
                is_active: stan.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching standards:', error);
        } finally {
          setLoadingStandards(false);
        }
      };

      fetchStandards();
    }
  }, [formData.department_id]);
  // Fetch Medium options based on Department selection
  useEffect(() => {
    if (formData.department_id?.id && formData.standard_id) {
      const fetchMediums = async () => {
        try {
          const response = await getMediumByStandard(
            Number(formData.department_id?.id),
            Number(formData.standard_id),
          );
          setMediumOptions(
            response.data.map(
              (item: {
                mediums: { id: number; name: string; is_active: boolean };
              }) => ({
                id: item.mediums.id,
                name: item.mediums.name,
                is_active: item.mediums.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching medium:', error);
        } finally {
          setLoadingMediums(false);
        }
      };
      fetchMediums();
    }
  }, [formData.standard_id]);

  // Fetch Division options based on Standard selection
  useEffect(() => {
    if (formData.standard_id && formData?.department_id?.id) {
      const fetchDivisions = async () => {
        try {
          const response = await getDivisionByStandard(
            Number(formData?.department_id?.id),
            Number(formData.standard_id),
          );
          setDivisionOptions(
            response.data.map(
              (item: {
                divisions: { id: number; name: string; is_active: boolean };
              }) => ({
                id: item.divisions.id,
                name: item.divisions.name,
                is_active: item.divisions.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching division:', error);
        } finally {
          setLoadingDivisions(false);
        }
      };

      fetchDivisions();
    }
  }, [formData.standard_id]);

  // Fetch Total Fee Amount based on department, Standard, medium, and division selection
  useEffect(() => {
    if (
      formData.department_id?.id &&
      formData.standard_id &&
      formData.medium_id &&
      formData.division_id
    ) {
      const fetchTotalFeeAmount = async () => {
        try {
          const response = await getTotalFeeAmount({
            department_id: Number(formData.department_id?.id),
            standard_id: Number(formData.standard_id),
            medium_id: Number(formData.medium_id),
            division_id: Number(formData.division_id),
          });
          console.log(response);
          setFormData((prevData) => ({
            ...prevData,
            total_fee_amount: Number(response.data),
          }));
        } catch (error) {
          console.error('Error fetching FeeAmount:', error);
        }
      };
      fetchTotalFeeAmount();
    }
  }, [token, formData.division_id]);

  // Set today's date to GR_Date
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    setFormData((prevData) => ({
      ...prevData,
      gr_date: today,
    }));
  }, []);

  // Fetch Student Data If isEdit
  useEffect(() => {
    if (isEdit && id) {
      const fetchStudent = async () => {
        setLoading(true);
        try {
          const response = await getStudentByID(Number(id));
          const { success, data } = response;
          if (success) {
            const student = data;
            // Ensure establisheddate is formatted as YYYY-MM-DD
            if (student.gr_date) {
              const formatted_GR_Date = new Date(student.gr_date)
                .toISOString()
                .split('T')[0];
              const formatted_B_Date = new Date(student.birth_date)
                .toISOString()
                .split('T')[0];
              student.gr_date = formatted_GR_Date;
              student.birth_date = formatted_B_Date;
            }

            const updatedFormData = {
              id: student?.id || 0,
              gr_number: student?.gr_number || '',
              gr_date: student?.gr_date,
              department_id:
                {
                  id: student?.departmentDetails?.id,
                  name: student?.departmentDetails?.name,
                } || null,
              first_name: student?.first_name || '',
              middle_name: student?.middle_name || '',
              last_name: student?.last_name || '',
              gender: student?.gender || '',
              blood_group: student?.blood_group || '',
              religion_id: student?.religionDetails?.id || '',
              category_id: student?.categoryDetails?.id || '',
              subcaste_id: student?.subCasteDetails?.id || '',
              nationality: student?.nationality || 'Indian',
              birth_date: student?.birth_date,
              birth_place: student?.birth_place || '',
              shift_id: student?.shiftDetails?.id || '',
              medium_id: student?.mediumDetails?.id || '',
              standard_id: student?.standardDetails?.id || '',
              division_id: student?.divisionDetails?.id || '',
              roll_no: student?.roll_no || '',
              previous_school: student?.previous_school || '',
              address: student?.address || '',
              city: student?.city || '',
              state: student?.state || '',
              pincode: student?.pincode || '',
              country: student?.country || 'India',
              father_name: student?.father_name || '',
              father_phone: student?.father_phone || '',
              father_email: student?.father_email || '',
              father_occupation: student?.father_occupation || '',
              mother_name: student?.mother_name || '',
              mother_phone: student?.mother_phone || '',
              mother_email: student?.mother_email || '',
              mother_occupation: student?.mother_occupation || '',
              guardian_name: student?.guardian_name || '',
              guardian_phone: student?.guardian_phone || '',
              guardian_email: student?.guardian_email || '',
              guardian_occupation: student?.guardian_occupation || '',
              guardian_relationship: student?.guardian_relationship || '',
              total_fee_amount: student?.total_fee_amount,
              fee_category:
                {
                  id: student?.feeCategoryDetails?.id,
                  name: student?.feeCategoryDetails?.name,
                  percentage: student?.feeCategoryDetails?.percentage,
                } || null,
              fee_schedules:
                {
                  id: student?.feeSchedulesDetails?.id,
                  name: student?.feeSchedulesDetails?.name,
                } || null,
              fee_amount_payable: student?.fee_amount_payable || 0,
            };

            setFormData(updatedFormData);
          } else {
            console.error('Failed to fetch student:', response.error);
          }
        } catch (error) {
          console.error('Error fetching student:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchStudent();
    }
  }, [isEdit, id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      let newFormData = { ...prevData, [name]: value };

      if (name === 'fee_category') {
        const selectedCategory = feeCategory.find(
          (fc) => fc.id === parseInt(value),
        );
        if (selectedCategory && selectedCategory.percentage !== undefined) {
          const discount =
            (prevData.total_fee_amount * selectedCategory.percentage) / 100;
          const feeAmountPayable = (
            prevData.total_fee_amount - discount
          ).toFixed(2);
          newFormData = {
            ...newFormData,
            fee_category: {
              id: selectedCategory.id,
              name: selectedCategory.name,
              percentage: selectedCategory.percentage,
            },
            fee_amount_payable: Number(feeAmountPayable),
          };
        } else {
          newFormData = { ...newFormData, fee_amount_payable: 0 };
        }
      }

      return newFormData;
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!formData.father_phone && !formData.mother_phone) {
        notifyError('Please Provide Parent Contect Number');
        return;
      }
      const { department_id, fee_category, fee_schedules, ...formDataWithIds } =
        formData;
      const formDataToSend = {
        ...formDataWithIds,
        department_id: department_id ? department_id.id : null,
        fee_category: fee_category ? fee_category.id : null,
        fee_schedules: fee_schedules ? fee_schedules.id : null,
      };

      if (isEdit && id) {
        const response = await updateStudent(formDataToSend);
        if (response.success) {
          notifySuccess('Student Updated Successfully');
          navigate(STUDENT_LIST);
        } else {
          notifyError('Error Updating Student');
          console.error('Error submitting form:', response.error);
        }
      } else {
        const { department_id, fee_category, ...formDataWithIds } = formData;
        const formDataToSend = {
          ...formDataWithIds,
          department_id: department_id ? department_id.id : null,
          fee_category: fee_category ? fee_category.id : null,
        };
        console.log(formDataToSend);
        const response = await createStudent(formDataToSend);
        if (response.success) {
          notifySuccess('Student Registered Successfully!');
          setFormData({
            id: 0,
            gr_number: '',
            gr_date: '',
            department_id: null,
            first_name: '',
            middle_name: '',
            last_name: '',
            gender: '',
            blood_group: '',
            religion_id: '',
            category_id: '',
            subcaste_id: '',
            nationality: 'Indian',
            birth_date: '',
            birth_place: '',
            shift_id: '',
            medium_id: '',
            standard_id: '',
            division_id: '',
            roll_no: '',
            previous_school: '',
            address: '',
            city: '',
            state: '',
            pincode: '',
            country: 'India',
            father_name: '',
            father_phone: '',
            father_email: '',
            father_occupation: '',
            mother_name: '',
            mother_phone: '',
            mother_email: '',
            mother_occupation: '',
            guardian_name: '',
            guardian_phone: '',
            guardian_email: '',
            guardian_occupation: '',
            guardian_relationship: '',
            total_fee_amount: 0,
            fee_category: null,
            fee_schedules: null,
            fee_amount_payable: 0,
          });
          navigate(STUDENT_LIST);
        } else {
          notifyError('Error Registering Student: ' + response.error);
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      notifyError('Internal Server Error');
    }
  };

  const handleDepartmentChange = (selectedOption: any) => {
    setFormData((prev) => ({
      ...prev,
      department_id: selectedOption
        ? { id: selectedOption.value, name: selectedOption.label }
        : null,
    }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container bg-white">
      <div className="h-[calc(100vh-65px)] overflow-y-auto custome-scrollbar p-4">
        <h2 className="text-2xl font-medium mb-5">
          {isEdit ? 'Edit Student Information' : 'Student Information'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Department, GR Number & Date */}
          <div className="flex mr-1 justify-between">
            <div className="w-[48.5%]">
              <span className="block text-sm font-medium text-gray-600 mb-1">
                Department<span className="text-red-600 ml-1">*</span>
              </span>
              <AsyncPaginate
                value={
                  formData.department_id
                    ? {
                        label: formData.department_id.name,
                        value: formData.department_id.id,
                      }
                    : { label: 'Select Department', value: '' }
                }
                loadOptions={departmentdropdownloadOptions}
                onChange={handleDepartmentChange}
                additional={{
                  page: 1,
                }}
                className="w-full"
                placeholder="Select Department"
              />
            </div>
            <div className="w-[23.2%]">
              <span className="block text-sm font-medium text-gray-600 mb-1">
                GR Number<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="number"
                name="gr_number"
                value={formData.gr_number}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full cursor-not-allowed"
                required
                // disabled
                // placeholder="Auto Fill"
              />
            </div>

            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                GR Date<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="date"
                name="gr_date"
                onChange={handleInputChange}
                value={formData.gr_date}
                className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
              />
            </div>
          </div>

          {/* Standard, Medium, Division & shift */}
          <div className="flex mr-1 justify-between">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Standard<span className="text-red-600 ml-1">*</span>
              </span>
              <div>
                {loadingStandards ? (
                  <select
                    disabled
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                  >
                    <option value="">Please Select Department</option>
                  </select>
                ) : (
                  <select
                    id="standard_id"
                    name="standard_id"
                    value={formData.standard_id || 'Select Department'}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                    required
                  >
                    <option value="">Select Standard</option>
                    {standardOptions
                      .filter((stan) => stan.is_active)
                      .map((stan) => (
                        <option key={stan.id} value={stan.id}>
                          {stan.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Medium<span className="text-red-600 ml-1">*</span>
              </span>
              <div>
                {loadingMediums ? (
                  <select
                    disabled
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                  >
                    <option value="">Please Select Standard</option>
                  </select>
                ) : (
                  <select
                    id="medium_id"
                    name="medium_id"
                    value={formData.medium_id || 'Select Medium'}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                    required
                  >
                    <option value="">Select Medium</option>
                    {mediumOptions
                      .filter((medi) => medi.is_active)
                      .map((medi) => (
                        <option key={medi.id} value={medi.id}>
                          {medi.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Division<span className="text-red-600 ml-1">*</span>
              </span>
              <div>
                {loadingDivisions ? (
                  <select
                    disabled
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                  >
                    <option value="">Please Select Standard</option>
                  </select>
                ) : (
                  <select
                    id="division_id"
                    name="division_id"
                    value={formData.division_id || 'Select Division'}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                    required
                  >
                    <option value="">Select Division</option>
                    {divisionOptions
                      .filter((div) => div.is_active)
                      .map((div) => (
                        <option key={div.id} value={div.id}>
                          {div.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Shift<span className="text-red-600 ml-1">*</span>
              </span>
              <select
                name="shift_id"
                id="shift_id"
                value={formData.shift_id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full"
                required
              >
                <option value="">Select Shift</option>
                {shift
                  .filter((shift) => shift.is_active)
                  .map((shift) => (
                    <option key={shift.id} value={shift.id}>
                      {shift.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* First Name, Middle Name, Last Name, & Gender */}
          <div className="flex mr-1 justify-between">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                First Name<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
                placeholder="Ex. Krishna"
              />
            </div>

            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Middle Name
              </span>
              <input
                type="text"
                name="middle_name"
                value={formData.middle_name}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. Vasudev"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Last Name<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
                placeholder="Ex. Yadav"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Gender<span className="text-red-600 ml-1">*</span>
              </span>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="flex mr-1 justify-between">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Nationality<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="text"
                name="nationality"
                value={formData.nationality}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1">
                Religion<span className="text-red-600 ml-1">*</span>
              </span>
              <select
                name="religion_id"
                value={formData.religion_id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Religion</option>
                {religions
                  .filter((religion) => religion.is_active)
                  .map((religion) => (
                    <option key={religion.id} value={religion.id}>
                      {religion.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Category<span className="text-red-600 ml-1">*</span>
              </span>
              <select
                name="category_id"
                value={formData.category_id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Category</option>
                {categories
                  .filter((category) => category.is_active)
                  .map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Sub Caste<span className="text-red-600 ml-1">*</span>
              </span>
              <select
                name="subcaste_id"
                value={formData.subcaste_id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Sub Caste</option>
                {subCastes
                  .filter((subCastes) => subCastes.is_active)
                  .map((subCaste) => (
                    <option key={subCaste.id} value={subCaste.id}>
                      {subCaste.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="flex mr-1 justify-between">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Birth Date<span className="text-red-600 ml-1">*</span>
              </span>
              <input
                type="date"
                name="birth_date"
                value={formData.birth_date}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Birth Place
              </span>
              <input
                type="text"
                name="birth_place"
                value={formData.birth_place}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. Mathura"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Roll No
              </span>
              <input
                type="number"
                name="roll_no"
                value={formData.roll_no}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. 001"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Previous School
              </span>
              <input
                type="text"
                name="previous_school"
                value={formData.previous_school}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. Gurukul"
              />
            </div>
          </div>

          <div className="flex mr-1 justify-between">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Blood Group
              </span>
              <select
                name="blood_group"
                value={formData.blood_group}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              >
                <option value="">Select Blood Group</option>
                <option value="O+">O+</option>
                <option value="O-">O-</option>
                <option value="A+">A+</option>
                <option value="A-">A-</option>
                <option value="B+">B+</option>
                <option value="B-">B-</option>
                <option value="AB+">AB+</option>
                <option value="AB-">AB-</option>
              </select>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Total Fee
              </span>
              <input
                type="number"
                name="total_fee_amount"
                value={formData.total_fee_amount}
                className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full cursor-not-allowed"
                required
                disabled
                placeholder="Fee Amount"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Fee Category
              </span>
              <select
                name="fee_category"
                value={formData.fee_category?.id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Fee Category</option>
                {feeCategory
                  .filter((fc) => fc.is_active)
                  .map((fc) => (
                    <option key={fc.id} value={fc.id}>
                      {fc.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Fee Amount Payable
              </span>
              <input
                type="number"
                name="fee_amount_payable"
                value={formData.fee_amount_payable}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border text-gray-600 border-slate-400 p-1.5 w-full cursor-not-allowed"
                required
                disabled
                placeholder="Amount Payable"
              />
            </div>
          </div>

          <div className="flex mr-1 justify-start">
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Fee Schedules
              </span>
              <select
                name="fee_schedules"
                value={formData.fee_schedules?.id}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                required
              >
                <option value="">Select Fee Schedule</option>
                {feeSchedule
                  .filter((fs) => fs.is_active)
                  .map((fs) => (
                    <option key={fs.id} value={fs.id}>
                      {fs.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div
            className="border-b border-dashed border-black"
            style={{ marginTop: '40px', marginBottom: '40px' }}
          ></div>

          <h2 className="text-2xl font-medium my-5">Address</h2>
          <div className="flex mr-1 justify-between">
            <div className="w-[48.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Address
              </span>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. 8/A Gaytri Krupa Society"
              />
            </div>

            <div className="w-[23.2%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Country
              </span>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>
            <div className="w-[23%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                State
              </span>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. Gujarat"
              />
            </div>
          </div>

          <div className="flex mr-1 justify-between">
            <div className="w-[48.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                City
              </span>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. Ahmedabad"
              />
            </div>

            <div className="w-[48.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Pincode
              </span>
              <input
                type="text"
                name="pincode"
                value={formData.pincode}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                placeholder="Ex. 384002"
              />
            </div>
          </div>

          <div
            className="border-b border-dashed border-black"
            style={{ marginTop: '40px', marginBottom: '40px' }}
          ></div>

          <div className="flex mr-1 justify-between">
            <div className="w-[48.5%]">
              <h2 className="text-2xl font-medium mb-4">
                Father&apos;s Details
              </h2>

              <div className="flex mr-1 justify-between mb-4">
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Father&apos;s Name
                  </span>
                  <input
                    type="text"
                    name="father_name"
                    value={formData.father_name}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>

                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Father&apos;s Email
                  </span>
                  <input
                    type="email"
                    name="father_email"
                    value={formData.father_email}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
              </div>
              <div className="flex mr-1 justify-between">
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Father&apos;s MobileNo
                  </span>
                  <input
                    type="text"
                    name="father_phone"
                    value={formData.father_phone}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Father&apos;s Occupation
                  </span>
                  <input
                    type="text"
                    name="father_occupation"
                    value={formData.father_occupation}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
              </div>
            </div>
            <div className="w-[48.5%]">
              <h2 className="text-2xl font-medium mb-4">
                Mother&apos;s Details
              </h2>

              <div className="flex mr-1 justify-between mb-4">
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Mother&apos;s Name
                  </span>
                  <input
                    type="text"
                    name="mother_name"
                    value={formData.mother_name}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>

                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Mother&apos;s Email
                  </span>
                  <input
                    type="email"
                    name="mother_email"
                    value={formData.mother_email}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
              </div>
              <div className="flex mr-1 justify-between">
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Mother&apos;s MobileNo
                  </span>
                  <input
                    type="text"
                    name="mother_phone"
                    value={formData.mother_phone}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
                <div className="w-[48.5%]">
                  <span className="block text-sm font-medium mb-1 text-gray-600">
                    Mother&apos;s Occupation
                  </span>
                  <input
                    type="text"
                    name="mother_occupation"
                    value={formData.mother_occupation}
                    onChange={handleInputChange}
                    className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="border-b border-dashed border-black"
            style={{ marginTop: '40px', marginBottom: '40px' }}
          ></div>

          <h2 className="text-2xl font-medium mb-4">Guardian&apos;s Details</h2>
          <div className="flex mr-1 justify-between">
            <div className="w-[31.8%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Guardian&apos;s Name
              </span>
              <input
                type="text"
                name="guardian_name"
                value={formData.guardian_name}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>

            <div className="w-[31.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Guardian&apos;s Email
              </span>
              <input
                type="email"
                name="guardian_email"
                value={formData.guardian_email}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>

            <div className="w-[31.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Guardian&apos;s Contact
              </span>
              <input
                type="text"
                name="guardian_phone"
                value={formData.guardian_phone}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>
          </div>
          <div className="flex mr-1 justify-between">
            <div className="w-[48.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Guardian&apos;s Occupation
              </span>
              <input
                type="text"
                name="guardian_occupation"
                value={formData.guardian_occupation}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>
            <div className="w-[48.5%]">
              <span className="block text-sm font-medium mb-1 text-gray-600">
                Guardian&apos;s Relation
              </span>
              <input
                type="text"
                name="guardian_relationship"
                value={formData.guardian_relationship}
                onChange={handleInputChange}
                className="bg-transparent rounded-lg border border-slate-400 text-gray-600 p-1.5 w-full"
              />
            </div>
          </div>

          <div
            className="border-b border-dashed border-black"
            style={{ marginTop: '40px', marginBottom: '20px' }}
          ></div>

          <div className="flex justify-center mb-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
            >
              {isEdit ? 'Update Student' : 'Register Student'}
            </button>
            <button
              className="bg-red-500 hover:bg-red-600 text-white p-2 rounded ml-2"
              onClick={() => {
                navigate(STUDENT_LIST);
              }}
            >
              {isEdit ? 'Cancle' : 'Cancle Registration'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUpdate;
