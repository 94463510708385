import { RouteObject } from 'react-router-dom';
import List from '../../pages/payment/feeSchedules/List';
import AddUpdate from '../../pages/payment/feeSchedules/AddUpdate';

export const FEE_SCHEDULES_TITLE = 'Fee Schedules';
export const FEE_SCHEDULES_LIST = '/feeSchedules';
export const FEE_SCHEDULES_CREATE = '/feeSchedules/add';
export const FEE_SCHEDULES_UPDATE = '/feeSchedules/:id/edit';

const FeeSchedulesRoutes: RouteObject[] = [
  {
    path: FEE_SCHEDULES_LIST,
    element: <List />,
  },
  {
    path: FEE_SCHEDULES_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: FEE_SCHEDULES_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default FeeSchedulesRoutes;
