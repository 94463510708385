import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/medium/List';
import AddUpdate from '../../pages/settings/medium/AddUpdate';

export const MEDIUM_TITLE = 'Medium';
export const MEDIUM_LIST = '/medium';
export const MEDIUM_CREATE = '/medium/add';
export const MEDIUM_UPDATE = '/medium/:id/edit';

const MediumRoutes: RouteObject[] = [
  {
    path: MEDIUM_LIST,
    element: <List />,
  },
  {
    path: MEDIUM_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: MEDIUM_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default MediumRoutes;
