import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Branch } from '../../types/Branch';
import { getBranchByID } from '../../controller';
import { BRANCH_LIST, BRANCH_UPDATE } from '../../routes/branch/Branch';

const View: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [branch, setBranch] = useState<Branch | null>(null);
  const [loading, setLoading] = useState(true);
  const organizationInclude = 'organization';
  useEffect(() => {
    const fetchBranchDetail = async () => {
      setLoading(true);
      try {
        const { message, data, success } = await getBranchByID(
          Number(id),
          organizationInclude,
        );
        if (success) {
          setBranch(data);
          return;
        }
        console.error('Failed to fetch Branch detail:', message);
        return;
      } catch (error) {
        console.error('Error fetching Branch detail:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBranchDetail();
  }, [id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!branch) {
    return <div>Branch not found</div>;
  }

  return (
    <div className="m-5">
      <div className="flex justify-between bg-white p-4 rounded-t shadow-md">
        <h2 className="text-gray-800 text-xl font-medium ml-6 my-1">
          {branch?.name} Overview
        </h2>
        <div className="flex justify-between">
          <button
            className="bg-blue-500 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white mr-2"
            onClick={() =>
              branch.id &&
              navigate(BRANCH_UPDATE.replace(':id', branch.id.toString()))
            }
          >
            Edit
          </button>
          <button
            className="bg-gray-500 cursor-pointer text-sm font-medium rounded p-2 hover:bg-gray-600 text-white"
            onClick={() => navigate(BRANCH_LIST)}
          >
            Back
          </button>
        </div>
      </div>
      <div className="border-b border-dashed border-gray-300"></div>
      <div className="bg-white p-4 rounded-b shadow-md flex">
        <div className="py-2 ml-6 space-y-5">
          <p className="font-normal text-slate-400">Organization Name</p>
          <p className="font-normal text-slate-400">Branch Name</p>
          <p className="font-normal text-slate-400">Email</p>
          <p className="font-normal text-slate-400">Phone</p>
          <p className="font-normal text-slate-400">Established Date</p>
          <p className="font-normal text-slate-400">Active Status</p>
          <p className="font-normal text-slate-400">Address</p>
        </div>

        <div className="py-2 space-y-5 ml-52">
          <p>{branch?.organization?.name}</p>
          <p>{branch.name}</p>
          <p>{branch.email}</p>
          <p>{branch.phone}</p>
          <p>{new Date(branch.established_date).toLocaleDateString()}</p>
          <p>{branch.is_active ? 'Active' : 'Inactive'}</p>
          <p>{branch.address}</p>
        </div>
      </div>
    </div>
  );
};

export default View;
