import React, { useEffect, useState } from 'react';
import { FaRegEdit, FaEye } from 'react-icons/fa';
import { MdDelete, MdRestoreFromTrash } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../components/DataTable';
import { AsyncPaginate } from 'react-select-async-paginate';
import type { LoadOptions } from 'react-select-async-paginate';
import {
  archiveStudent,
  getArchiveStudent,
  getDivisionByStandard,
  getSpecificStudents,
  getStudent,
  restoreStudent,
} from '../../controller';
import {
  STUDENT_ARCHIVE_LIST,
  STUDENT_CREATE,
  STUDENT_LIST,
  STUDENT_UPDATE,
  STUDENT_VIEW,
} from '../../routes/student/Student';
import { getDepartment } from '../../controller/api/Department';
import {
  getMediumByStandard,
  getMediumsByDepartment,
} from '../../controller/api/Medium';
import { getStandardByDepartment } from '../../controller/api/Standard';
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from '../../components/CustomToast';
import ConfirmationDialog from '../../components/ConfirmationDialog';

interface StudentInterface {
  id: number;
  gr_number: number;
  gr_date: Date;
  first_name: string;
  middle_name: string;
  last_name: string;
  gender: string;
  bloodGroup?: string;
  religion: number;
  category: number;
  subCaste: number;
  nationality: string;
  birthDate: Date;
  birthPlace: string;
  shift: string;
  medium: number;
  standard: number;
  division: number;
  roll_no: number;
  previousSchool: string;
  department: number;
  address: string;
  city: string;
  state: string;
  pincode: number;
  country: string;
  fatherName: string;
  fatherMobileNo: string;
  fatherEmail?: string;
  fatherOccupation: string;
  motherName: string;
  motherMobileNo?: string;
  motherEmail?: string;
  motherOccupation: string;
  guardianName?: string;
  guardianMobileNo?: string;
  guardianEmail?: string;
  guardianOccupation?: string;
  guardianRelationship?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  standardDetails: {
    id: number;
    name: string;
  };
  mediumDetails: {
    id: number;
    name: string;
  };
  departmentDetails: {
    id: number;
    name: string;
  };
  divisionDetails: {
    id: number;
    name: string;
  };
}

interface DepartmentDropdownOption {
  id: number;
  name: string;
}

interface DropdownOption {
  id: number;
  name: string;
}

interface Additional {
  page: number;
}

// Function For Fetch Department Data
const departmentdropdownloadOptions: LoadOptions<any, any, Additional> = async (
  inputValue,
  loadedOptions,
  additional = { page: 1 },
) => {
  const { page } = additional;
  const limit = 10;
  const filter = undefined;
  const { data } = await getDepartment({ page, limit, filter });

  return {
    options: data.departments.map((dep: DepartmentDropdownOption) => ({
      label: dep.name,
      value: dep.id,
    })),
    hasMore: data.currentPage < data.totalPages,
    additional: {
      page: page + 1,
    },
  };
};

const List: React.FC<{ isArchive?: boolean }> = ({ isArchive = false }) => {
  const navigate = useNavigate();

  const [students, setStudents] = useState<StudentInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState<
    DepartmentDropdownOption[]
  >([]);

  // State For Standard Medium Data
  const [standardOptions, setStandardOptions] = useState<DropdownOption[]>([]);
  const [loadingStandards, setLoadingStandards] = useState(true);
  const [selectedStandard, setSelectedStandards] = useState<DropdownOption[]>(
    [],
  );

  // State For Manage Medium Data
  const [mediumOptions, setMediumOptions] = useState<DropdownOption[]>([]);
  const [selectedMedium, setSelectedMedium] = useState<DropdownOption[]>([]);
  const [loadingMediums, setLoadingMediums] = useState(true);

  // State For Manage Division Data
  const [divisionOptions, setDivisionOptions] = useState<DropdownOption[]>([]);
  const [selectedDivision, setSelectedDivision] = useState<DropdownOption[]>(
    [],
  );
  const [loadingDivisions, setLoadingDivisions] = useState(true);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  // Fetch Standard options based on Department selection
  useEffect(() => {
    if (departmentOptions.length > 0) {
      const fetchStandards = async () => {
        try {
          const response = await getStandardByDepartment(
            Number(departmentOptions[0].id),
          );
          setStandardOptions(
            response.data.standards.map(
              (stan: { id: number; name: string; is_active: boolean }) => ({
                id: stan.id,
                name: stan.name,
                is_active: stan.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching standards:', error);
        } finally {
          setLoadingStandards(false);
        }
      };

      fetchStandards();
    }
  }, [departmentOptions]);

  // Fetch Medium options based on Department selection
  useEffect(() => {
    if (departmentOptions.length > 0 && selectedStandard.length > 0) {
      const fetchMediums = async () => {
        try {
          const response = await getMediumByStandard(
            Number(departmentOptions[0].id),
            Number(selectedStandard[0].id),
          );
          setMediumOptions(
            response.data.map(
              (item: {
                mediums: { id: number; name: string; is_active: boolean };
              }) => ({
                id: item.mediums.id,
                name: item.mediums.name,
                is_active: item.mediums.is_active,
              }),
            ),
          );
        } catch (error) {
          console.error('Error fetching medium:', error);
        } finally {
          setLoadingMediums(false);
        }
      };
      fetchMediums();
    }
  }, [departmentOptions, selectedStandard]);

  // Fetch Division options based on Standard selection
  useEffect(() => {
    if (selectedStandard.length > 0 && departmentOptions.length > 0) {
      const fetchDivisions = async () => {
        try {
          const response = await getDivisionByStandard(
            Number(departmentOptions[0].id),
            Number(selectedStandard[0].id),
          );
          if (response.success && response.data) {
            setDivisionOptions(
              response.data.map(
                (item: {
                  divisions: { id: number; name: string; is_active: boolean };
                }) => ({
                  id: item.divisions.id,
                  name: item.divisions.name,
                  is_active: item.divisions.is_active,
                }),
              ),
            );
          } else {
            console.error('Unexpected response structure:', response);
          }
        } catch (error) {
          console.error('Error fetching division:', error);
        } finally {
          setLoadingDivisions(false);
        }
      };
      fetchDivisions();
    }
  }, [selectedStandard, departmentOptions]);

  const columns: TableColumn<StudentInterface>[] = [
    {
      name: 'GR No',
      selector: (row: StudentInterface) => row.gr_number,
      sortable: true,
      width: '8%',
      center: true,
    },
    {
      name: 'FullName',
      width: '20%',
      selector: (row: StudentInterface) =>
        `${row.first_name} ${row.middle_name} ${row.last_name}`,
      sortable: true,
    },
    {
      name: 'Department',
      selector: (row: StudentInterface) => row.departmentDetails?.name,
      sortable: true,
    },
    {
      name: 'Standard',
      selector: (row: StudentInterface) => row.standardDetails?.name,
      sortable: true,
      // width: '10%',
    },
    {
      name: 'Medium',
      selector: (row: StudentInterface) => row.mediumDetails?.name,
      sortable: true,
      // width: '10%',
    },
    {
      name: 'Division',
      selector: (row: StudentInterface) => row.divisionDetails?.name,
      sortable: true,
      // width: '10%',
    },
    {
      name: 'Gender',
      selector: (row: StudentInterface) => row.gender,
      sortable: true,
      // width: '10%',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          {isArchive ? (
            <>
              <MdRestoreFromTrash
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() => handleRestore(Number(row?.id))}
              />
            </>
          ) : (
            <>
              <FaEye
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() =>
                  row?.id &&
                  navigate(STUDENT_VIEW.replace(':id', row?.id?.toString()))
                }
              />
              <FaRegEdit
                className="text-xl text-blue-500 cursor-pointer mr-1"
                onClick={() =>
                  row?.id &&
                  navigate(STUDENT_UPDATE.replace(':id', row?.id?.toString()))
                }
              />
              <MdDelete
                className="text-xl text-red-500 cursor-pointer"
                onClick={() => row.id && handleDelete(row.id)}
              />
            </>
          )}
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const fetchStudents = async (page: number, limit: number) => {
    setLoading(true);
    try {
      if (isArchive) {
        const { success, data } = await getArchiveStudent({ page, limit });
        if (success) {
          setStudents(data.students);
          setTotalRows(data.totalStudents);
          setCurrentPage(data.currentPage);
        }
      } else {
        const { success, data } = await getStudent({ page, limit });
        if (success) {
          setStudents(data.students);
          setTotalRows(data.totalStudents);
          setCurrentPage(data.currentPage);
        }
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage, isArchive]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchStudents(page, newPerPage);
  };

  const handleDepartmentChange = (selectedOption: any) => {
    setDepartmentOptions(
      selectedOption
        ? [{ id: selectedOption.value, name: selectedOption.label }]
        : [],
    );
    setSelectedStandards([]);
    setSelectedMedium([]);
    setSelectedDivision([]);
    setLoadingDivisions(true);
  };

  const handleStandardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedStandards([{ id: id, name: name }]);
    setSelectedDivision([]);
  };

  const handleMediumChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedMedium([{ id: id, name: name }]);
  };

  const handleDivisionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const id = parseInt(value);
    setSelectedDivision([{ id: id, name: name }]);
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu is above other elements
    }),
  };

  const handleSpecificStudentSearch = async () => {
    const dId =
      departmentOptions.length > 0 ? departmentOptions[0].id : undefined;
    const mId = selectedMedium.length > 0 ? selectedMedium[0].id : undefined;
    const sId =
      selectedStandard.length > 0 ? selectedStandard[0].id : undefined;
    const divId =
      selectedDivision.length > 0 ? selectedDivision[0].id : undefined;

    if (dId || mId || sId || divId) {
      setLoading(true);
      try {
        const response = await getSpecificStudents(dId, mId, sId, divId);
        if (response.success) {
          setStudents(response.data.students);
        }
      } catch (error) {
        notifyError('Error fetching specific students');
        console.error('Error fetching students:', error);
      } finally {
        setLoading(false);
      }
    } else {
      notifyWarning('Please select at least one criterion');
    }
  };

  // Function For Handle Student Deletion
  const handleDelete = async (studentId: number) => {
    openDialog(
      'Remove Student',
      'Are you sure you want to remove this student?',
      async () => {
        try {
          const response = await archiveStudent(studentId);
          if (response.success) {
            setStudents(students.filter((stud) => stud.id !== studentId));
            notifySuccess('Student Remove successfully!');
          } else {
            console.error('Failed to Remove Student:', response.error);
            notifyError('Error Removing Student');
          }
        } catch (error) {
          notifyError('Internal Server Error');
          console.error('Error Removing Student:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  const handleRestore = async (id: number) => {
    openDialog(
      'Restore Student',
      'Are you sure you want to restore this student?',
      async () => {
        try {
          if (isArchive) {
            const response = await restoreStudent(id);
            if (response.success) {
              notifySuccess('Student Restored Successfully');
              fetchStudents(currentPage, perPage);
            }
          }
        } catch (error) {
          notifyError('Error Restoring Student');
          console.error('Error restoring student:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-1">
          <h2 className="text-gray-800 text-2xl font-medium">
            {isArchive ? `Archive Student List` : `Student List`}
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-blue-500 ml-2 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white flex"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FiFilter className="m-1" />
            </button>
            {isArchive ? (
              <button
                className="bg-gray-900 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-gray-700 text-white"
                onClick={() => navigate(STUDENT_LIST)}
              >
                Back
              </button>
            ) : (
              <>
                <button
                  className="bg-green-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-green-600 text-white"
                  onClick={() => navigate(STUDENT_CREATE)}
                >
                  Create
                </button>
                <button
                  className="bg-red-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-red-600 text-white"
                  onClick={() => navigate(STUDENT_ARCHIVE_LIST)}
                >
                  Archive
                </button>
              </>
            )}
          </div>
        </div>
        {showFilter && (
          <div className="flex justify-between my-2 p-2">
            <div className="w-[23%]">
              <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Select Department
              </span>
              <AsyncPaginate
                value={
                  departmentOptions.length > 0
                    ? {
                        label: departmentOptions[0].name,
                        value: departmentOptions[0].id,
                      }
                    : null
                }
                loadOptions={departmentdropdownloadOptions}
                onChange={handleDepartmentChange}
                additional={{
                  page: 1,
                }}
                className="w-full"
                placeholder="Select Department"
                styles={customStyles}
              />
            </div>
            <div className="w-[23%]">
              <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Select Standard
              </span>
              <div>
                {loadingStandards ? (
                  <select
                    disabled
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Please Select Department</option>
                  </select>
                ) : (
                  <select
                    id="standard"
                    name="standard"
                    value={
                      selectedStandard.length > 0 ? selectedStandard[0].id : ''
                    }
                    onChange={handleStandardChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  >
                    <option value="">Select Standard</option>
                    {standardOptions.map((stan) => (
                      <option key={stan.id} value={stan.id}>
                        {stan.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="w-[23%]">
              <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Medium
              </span>
              <div>
                {loadingMediums ? (
                  <select
                    disabled
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Please Select Standard</option>
                  </select>
                ) : (
                  <select
                    id="medium"
                    name="medium"
                    value={
                      selectedMedium.length > 0 ? selectedMedium[0].id : ''
                    }
                    onChange={handleMediumChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  >
                    <option value="">Select Medium</option>
                    {mediumOptions.map((medi) => (
                      <option key={medi.id} value={medi.id}>
                        {medi.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="w-[23%]">
              <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Division
              </span>
              <div>
                {loadingDivisions ? (
                  <select
                    disabled
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Please Select Standard</option>
                  </select>
                ) : (
                  <select
                    id="division"
                    name="division"
                    value={
                      selectedDivision.length > 0 ? selectedDivision[0].id : ''
                    }
                    onChange={handleDivisionChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required
                  >
                    <option value="">Select Division</option>
                    {divisionOptions.map((div) => (
                      <option key={div.id} value={div.id}>
                        {div.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="mt-8">
              <button
                className="p-1 bg-blue-500 hover:bg-blue-600 cursor-pointer text-sm font-medium rounded text-white"
                onClick={handleSpecificStudentSearch}
              >
                Search
              </button>
            </div>
          </div>
        )}
        <div>
          <CustomDataTable
            columns={columns}
            data={students}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
