import React, { useEffect, useState } from 'react';
import { FaRegEdit, FaEye } from 'react-icons/fa';
import { MdDelete, MdRestoreFromTrash } from 'react-icons/md';
import { FiFilter } from 'react-icons/fi';
import { FcFullTrash } from 'react-icons/fc';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import CustomFilter from '../../components/CustomFilter';
import CustomDataTable from '../../components/DataTable';
import {
  ORGANIZATION_ARCHIVE_LIST,
  ORGANIZATION_CREATE,
  ORGANIZATION_LIST,
  ORGANIZATION_TITLE,
  ORGANIZATION_UPDATE,
  ORGANIZATION_VIEW,
} from '../../routes/organization/Organization';
import {
  archiveOrganization,
  changeOrganizationStatus,
  getArchiveOrganization,
  getOrganization,
  permanentDeleteOrganization,
  restoreOrganization,
} from '../../controller';
import { FilterCriteria } from '../../types/Comman';
import { Organization } from '../../types/Organizations';
import logo from '../../assets/images/logo.png';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { notifySuccess, notifyError } from '../../components/CustomToast';

const List: React.FC<{ isArchive?: boolean }> = ({ isArchive = false }) => {
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({
    column: [],
    operation: [],
    value: [],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  // Function For Fetch Organization Data
  const fetchOrganizations = async (
    page: number,
    limit: number,
    filter?: FilterCriteria,
  ) => {
    setLoading(true);
    try {
      if (isArchive) {
        const { success, data } = await getArchiveOrganization({
          page,
          limit,
          filter,
        });
        if (success) {
          setOrganizations(data.organizations);
          setTotalRows(data.totalOrganizations);
          setCurrentPage(data.currentPage);
        }
      } else {
        const { success, data } = await getOrganization({
          page,
          limit,
          filter,
        });
        if (success) {
          setOrganizations(data.organizations);
          setTotalRows(data.totalOrganizations);
          setCurrentPage(data.currentPage);
        }
      }
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRestore = async (id: number) => {
    openDialog(
      'Restore Organization',
      'Are you sure you want to restore this organization?',
      async () => {
        try {
          if (isArchive) {
            const response = await restoreOrganization(id);
            if (response.success) {
              notifySuccess('Organization Restored Successfully');
              fetchOrganizations(
                currentPage,
                perPage,
                filterCriteria || undefined,
              );
            } else {
              notifyError('Error Restoring Organization');
            }
          }
        } catch (error) {
          notifyError('Error Restoring Organization');
          console.error('Error restoring organization:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  const handlePermanentDelete = async (id: number) => {
    openDialog(
      'Permanently Delete Organization',
      'Are you sure you want to permanently delete this organization?',
      async () => {
        try {
          const response = await permanentDeleteOrganization(id);
          if (response.success) {
            notifySuccess('Organization Deleted Permanently');
            fetchOrganizations(
              currentPage,
              perPage,
              filterCriteria || undefined,
            );
          } else {
            notifyError('Error Deleting Organization');
            console.error('Error deleting organization:', response.message);
          }
        } catch (error) {
          notifyError('Error Deleting Organization');
          console.error('Error deleting organization:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  useEffect(() => {
    fetchOrganizations(currentPage, perPage, filterCriteria || undefined);
    // eslint-disable-next-line
  }, [currentPage, perPage, filterCriteria, isArchive]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchOrganizations(page, newPerPage, filterCriteria);
  };

  // Function For Handle Organization Activity Status
  const handleToggle = async (organizationId: number, checked: boolean) => {
    try {
      if (organizationId) {
        const response = await changeOrganizationStatus(organizationId);
        if (response.success) {
          const updatedOrganizations = organizations.map((org) =>
            org.id === organizationId ? { ...org, is_active: checked } : org,
          );
          setOrganizations(updatedOrganizations);
        } else {
          console.error(
            'Failed to toggle organization status:',
            response.message,
          );
        }
      }
    } catch (error) {
      console.error('Error toggling organization status:', error);
    }
  };

  // Function For Handle Organization Deletion
  const handleDelete = async (organizationId: number) => {
    openDialog(
      'Delete Organization',
      'Are you sure you want to delete this organization?',
      async () => {
        try {
          const response = await archiveOrganization(organizationId);
          if (response.success) {
            notifySuccess('Organization Deleted');
            setOrganizations(
              organizations.filter((org) => org.id !== organizationId),
            );
          } else {
            notifyError('Error Deleting Organization');
            console.error('Failed to delete organization:', response.message);
          }
        } catch (error) {
          notifyError('Error Deleting Organization');
          console.error('Error deleting organization:', error);
        } finally {
          closeDialog();
        }
      },
    );
  };

  const formatDate = (dateString: string | string[]) => {
    if (Array.isArray(dateString)) {
      // If dateString is already an array, return the first element
      return dateString[0];
    } else {
      // If dateString is a single string, format it and return
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    }
  };

  const handleApplyFilter = async (filter: FilterCriteria) => {
    // Format date before sending to server
    const formattedFilter = {
      ...filter,
      value: filter.value ? formatDate(filter.value) : '',
    };
    setFilterCriteria(formattedFilter);
    setShowFilter(false);
  };

  const organizationColumns = [
    { value: 'organization_name', label: 'Organization Name' },
    { value: 'email', label: 'Email' },
    { value: 'establisheddate', label: 'Established Date' },
    { value: 'isactive', label: 'Active Status' },
  ];

  const operations = [
    { value: 'equals', label: 'Equals' },
    { value: 'not_equals', label: 'Not Equals' },
    { value: 'like', label: 'Like' },
    { value: 'greater_than', label: 'Greater Than' },
    { value: 'less_than', label: 'Less Than' },
  ];

  // Data Table Columns
  const columns: TableColumn<Organization>[] = [
    {
      name: 'No.',
      cell: (_row: Organization, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      width: '55px',
    },
    {
      name: 'Logo',
      cell: (row) => (
        <img className="w-9 h-9 rounded-full" src={logo} alt="Org Logo" />
      ),
      width: '65px',
      center: true,
    },
    {
      name: 'Organization Name',
      selector: (row: Organization) => row.name,
      sortable: true,
      // width: '230px',
    },
    {
      name: 'Email',
      selector: (row: Organization) => row.email,
      sortable: true,
      // width: '240px',
    },
    {
      name: 'PhoneNo',
      selector: (row: Organization) => row.phone,
      sortable: true,
      width: '125px',
    },
    {
      name: 'Established Date',
      selector: (row: Organization) =>
        new Date(row.established_date).toLocaleDateString(),
      sortable: true,
      width: '155px',
      center: true,
    },
    {
      name: 'Active Status',
      cell: (row: Organization) => (
        <Toggle
          checked={row.is_active}
          onChange={(event: { target: { checked: boolean } }) =>
            row?.id && handleToggle(row?.id, event.target.checked)
          }
        />
      ),
      width: '130px',
      center: true,
      omit: isArchive,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end mr-3">
          {isArchive ? (
            <>
              <MdRestoreFromTrash
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() => handleRestore(Number(row?.id))}
              />
              <MdDelete
                className="text-xl text-red-500 cursor-pointer"
                onClick={() => handlePermanentDelete(Number(row.id))}
              />
            </>
          ) : (
            <>
              <FaEye
                className="text-xl text-green-500 cursor-pointer mr-2"
                onClick={() =>
                  row?.id &&
                  navigate(
                    ORGANIZATION_VIEW.replace(':id', row?.id?.toString()),
                  )
                }
              />
              <FaRegEdit
                className="text-xl text-blue-500 cursor-pointer mr-1"
                onClick={() =>
                  row?.id &&
                  navigate(
                    ORGANIZATION_UPDATE.replace(':id', row?.id?.toString()),
                  )
                }
              />
              <FcFullTrash
                className="text-xl text-red-500 cursor-pointer"
                onClick={() => row.id && handleDelete(row.id)}
              />
            </>
          )}
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  return (
    <>
      <div className="m-5">
        {/* Header */}
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            {isArchive
              ? `Archive ${ORGANIZATION_TITLE} List`
              : `${ORGANIZATION_TITLE} List`}
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-blue-500 ml-2 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white flex"
              onClick={() => setShowFilter(!showFilter)}
            >
              <FiFilter className="m-1" />
            </button>
            {isArchive ? (
              <button
                className="bg-gray-900 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-gray-700 text-white"
                onClick={() => navigate(ORGANIZATION_LIST)}
              >
                Back
              </button>
            ) : (
              <>
                <button
                  className="bg-green-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-green-600 text-white"
                  onClick={() => navigate(ORGANIZATION_CREATE)}
                >
                  Create
                </button>
                <button
                  className="bg-red-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-red-600 text-white"
                  onClick={() => navigate(ORGANIZATION_ARCHIVE_LIST)}
                >
                  Archive
                </button>
              </>
            )}
          </div>
        </div>
        {/* Filter */}
        {showFilter && (
          <CustomFilter
            columns={organizationColumns}
            operations={operations}
            onApplyFilter={handleApplyFilter}
          />
        )}
        {/* DataTable */}
        <div>
          <CustomDataTable
            columns={columns}
            data={organizations}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
