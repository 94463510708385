import { GetRequest, Response } from '../../types/Comman';
import {
  ResponseDivision,
  AssignDivisions,
  assignDivisionsToDepartment,
} from '../../types/Division';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'division';

// Division
export const getDivision = async (body: GetRequest): Promise<Response<any>> => {
  const { page = 1, limit = 10, filter, search } = body;
  let url = `${baseURL}/get-all-division?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getDivisionByStandard = async (
  department_id: number,
  sid_id: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/divisionsbystandard?department_id=${department_id}&sid_id=${sid_id}`,
  );
  return response.data;
};

// export const getDivisionByDepartment = async (
//   department_id: number,
// ): Promise<any> => {
//   const response = await AxiosInstance.get(
//     `${baseURL}/divisionsbydepartment/${department_id}`,
//   );
//   return response.data;
// };

// export const divisionsAssign = async (body: assignDivisions) => {
//   const response = await AxiosInstance.post(
//     `${baseURL}/assign-division-to-standard`,
//     body,
//   );
//   return response.data;
// };

export const divisionsAssignToDepartment = async (
  body: assignDivisionsToDepartment,
) => {
  const response = await AxiosInstance.post(
    `${baseURL}/assign-division-to-department`,
    body,
  );
  return response.data;
};

export const createDivision = async (
  body: ResponseDivision,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/create-division`, body);
  return response.data;
};

export const updateDivision = async (
  body: ResponseDivision,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-division/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveDivision = async (
  divisionId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-division/${divisionId}`,
  );
  return response.data;
};

export const getDivisionByID = async (
  DivisionId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/get-division/${DivisionId}`,
  );
  return response.data;
};

export const assignDivision = async (
  body: AssignDivisions,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/assign-division`, body);
  return response.data;
};
