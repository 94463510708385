import React, { useEffect, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete, MdAssignmentAdd } from 'react-icons/md';
import { IoArchiveOutline } from 'react-icons/io5';
import { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import CustomDataTable from '../../../components/DataTable';
import { archiveDepartment, getDepartment } from '../../../controller';
import {
  DEPARTMENT_CREATE,
  DEPARTMENT_DIVISION,
  DEPARTMENT_MEDIUM,
} from '../../../routes/settings/Department';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { notifyError, notifySuccess } from '../../../components/CustomToast';

interface DepartmentInterface {
  id: number;
  name: string;
  established_date: Date;
  branch_id: number;
  is_active: boolean;
  branch: { id: number | null; name: string };
  branch_name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

const List: React.FC = () => {
  const navigate = useNavigate();

  const [departments, setDepartments] = useState<DepartmentInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const branchInclude = 'branch';

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchDepartments = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const { data, success } = await getDepartment({
        page,
        limit,
        include: branchInclude,
      });
      if (success) {
        setDepartments(data.departments);
        setTotalRows(data.totalDepartments);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchDepartments(page, newPerPage);
  };

  const handleAssignDivision = (id: string) => {
    navigate(DEPARTMENT_DIVISION.replace(':id', id));
  };
  const handleAssignMedium = (id: string) => {
    navigate(DEPARTMENT_MEDIUM.replace(':id', id));
  };
  const columns: TableColumn<DepartmentInterface>[] = [
    {
      name: 'No.',
      cell: (_row: DepartmentInterface, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
      width: '8%',
    },
    {
      name: 'Branch',
      selector: (row: DepartmentInterface) => row.branch?.name,
      sortable: true,
      width: '18%',
    },
    {
      name: 'Department',
      selector: (row: DepartmentInterface) => row.name,
      sortable: true,
      width: '18%',
    },
    {
      name: 'Established Date',
      selector: (row: DepartmentInterface) =>
        new Date(row.established_date).toLocaleDateString(),
      sortable: true,
      width: '15%',
    },
    {
      name: 'Status',
      selector: (row: DepartmentInterface) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Division',
      cell: (row: DepartmentInterface) => (
        <>
          <MdAssignmentAdd
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() => {
              handleAssignDivision(String(row.id));
            }}
          />
        </>
      ),
      center: true,
    },
    {
      name: 'Medium',
      cell: (row: DepartmentInterface) => (
        <>
          <MdAssignmentAdd
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() => {
              handleAssignMedium(String(row.id));
            }}
          />
        </>
      ),
      center: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          {/* <FaEye className="text-xl text-green-500 cursor-pointer mr-2" /> */}
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() => navigate(`/department/${row.id}/edit`)}
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Department',
      'Are you sure you want to delete this department?',
      async () => {
        try {
          const { success } = await archiveDepartment(id);
          if (!success) {
            notifyError('Failed to delete department');
            return;
          }
          setDepartments((prevDepartments) =>
            prevDepartments.filter((department) => department.id !== id),
          );
          notifySuccess('Department deleted successfully!');
        } catch (error) {
          console.error('Error deleting department:', error);
          notifyError('Error deleting department');
        } finally {
          closeDialog();
        }
      },
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            Department List
          </h2>
          <div className="flex justify-between">
            <button className="bg-blue-500 ml-2 cursor-pointer text-sm font-medium rounded p-2 hover:bg-blue-600 text-white flex">
              <FiFilter className="m-1" />
            </button>
            <button
              className="bg-green-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-green-600 text-white"
              onClick={() => navigate(DEPARTMENT_CREATE)}
            >
              Create
            </button>
            <button className="bg-red-500 ml-2 cursor-pointer text-xs font-medium rounded p-2 hover:bg-red-600 text-white">
              Archive
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={departments}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
