import React, { useEffect, useState } from 'react';
import {
  assignDivision,
  getDivision,
  getDivisionByStandard,
  getStandardByDepartment,
} from '../../../controller';
import { useParams } from 'react-router-dom';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { components } from 'react-select';
import { notifyError, notifySuccess } from '../../../components/CustomToast';

interface DivisionInterface {
  value: number;
  label: string;
}
const AssignDivision: React.FC = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState<any>();
  const [selectedDivisions, setSelectedDivisions] = useState<
    DivisionInterface[]
  >([]);
  const [formData, setFormData] = useState<any>({
    department_id: null,
    standard_id: null,
  });
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { success, message } = await assignDivision(formData);
      if (success) {
        notifySuccess(message);
      } else {
        notifyError(message);
      }
    } catch (error) {
      notifyError('Error Submitting Form');
      console.error('Error submitting form:', error);
    }
  };
  const getDepartment = (id: number) => {
    getStandardByDepartment(id)
      .then((res) => {
        setDepartment(res.data);
        setFormData({
          ...formData,
          department_id: res.data.id,
          standard_id: res.data.standards[0].id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelectChange = (data: any) => {
    const { value } = data.target;
    setFormData({
      ...formData,
      standard_id: value,
    });
  };

  useEffect(() => {
    if (formData.department_id && formData.standard_id) {
      getDivisionData(formData.department_id, formData.standard_id);
    }
  }, [formData.department_id, formData.standard_id]);

  const getDivisionData = (department_id: number, sid_id: number) => {
    getDivisionByStandard(department_id, sid_id)
      .then((res) => {
        setFormData({
          ...formData,
          division_ids: res.data.map(({ division_id }: any) => division_id),
        });
        const transformedData: DivisionInterface[] = res.data.map(
          ({ divisions }: any) => ({
            value: divisions.id,
            label: divisions.name,
          }),
        );

        setSelectedDivisions(transformedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectedDivision = (value: DivisionInterface[]) => {
    setSelectedDivisions(value as DivisionInterface[]);
    setFormData({
      ...formData,
      division_ids: value?.map((e) => e.value),
    });
  };
  useEffect(() => {
    getDepartment(Number(id));
  }, [id]);

  const standardloadOptions: LoadOptions<
    DivisionInterface,
    any,
    { page: number }
  > = async (
    search: string,
    loadedOptions: readonly DivisionInterface[],
    additional: { page: number } = { page: 1 },
  ) => {
    const page = additional.page;
    const limit = 10;
    try {
      const { data, success } = await getDivision({
        page,
        limit,
        search: search,
      });
      if (success) {
        return {
          options: data.divisions.map((division: any) => ({
            value: division.id,
            label: division.name,
          })),
          hasMore: data.totalPages > additional.page,
          additional: {
            page: additional.page + 1,
          },
        };
      } else {
        return {
          options: [],
          hasMore: false,
          additional: {
            page: additional.page + 1,
          },
        };
      }
    } catch (error) {
      console.error('Error fetching standards:', error);
      return {
        options: [],
        hasMore: false,
        additional: {
          page: additional.page + 1,
        },
      };
    }
  };

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              Assign Division
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <input
                      type="text"
                      name="name"
                      value={department?.name}
                      placeholder=" "
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      readOnly
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Department Name *
                    </span>
                  </div>
                  <div className="relative w-1/2">
                    <select
                      name="standard_id"
                      value={formData?.standard_id}
                      onChange={(data) => {
                        handleSelectChange(data);
                      }}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="" selected disabled>
                        Select Standard
                      </option>
                      {department?.standards?.map((standard: any) => (
                        <option key={standard.id} value={standard.id}>
                          {standard.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <AsyncPaginate
                    isMulti
                    required
                    value={selectedDivisions}
                    loadOptions={standardloadOptions}
                    additional={{ page: 1 }}
                    onChange={(value: any) =>
                      handleSelectedDivision(value as DivisionInterface[])
                    }
                    components={{
                      MultiValue: components.MultiValue,
                      Option: components.Option,
                    }}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignDivision;
