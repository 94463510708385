import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TableColumn } from 'react-data-table-component';
import CustomDataTable from '../../../components/DataTable';
import { archiveInstallment, getInstallment } from '../../../controller';
import { notifyError, notifySuccess } from '../../../components/CustomToast';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import {
  INSTALLMENT_CREATE,
  INSTALLMENT_UPDATE,
} from '../../../routes/payment/Installment';

interface InstallmentInterface {
  id?: number;
  name: string;
  amount: number;
  due_date: string | null;
  is_active: boolean;
  create_at?: Date;
  update_at?: Date;
  delete_at?: string | null;
}

const List: React.FC = () => {
  const navigate = useNavigate();
  const [installments, setInstallments] = useState<InstallmentInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [dialogOpen, setDialogOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const [dialogAction, setDialogAction] = useState<() => void>(() => {});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const openDialog = (title: string, message: string, action: () => void) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const fetchInstallments = async (page: number, limit: number) => {
    setLoading(true);
    try {
      const response = await getInstallment({ page, limit });
      const { success, data } = response;
      if (success) {
        setInstallments(data.installment);
        setTotalRows(data.totalInstallment);
        setCurrentPage(data.currentPage);
      }
    } catch (error) {
      console.error('Error fetching fee Installments:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstallments(currentPage, perPage);
    // eslint-disable-next-line
  }, [currentPage, perPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    await fetchInstallments(page, newPerPage);
  };

  const columns: TableColumn<InstallmentInterface>[] = [
    {
      name: 'No.',
      cell: (_row: InstallmentInterface, index: number) =>
        index + 1 + (currentPage - 1) * perPage,
      sortable: true,
    },
    {
      name: 'Installment',
      selector: (row: InstallmentInterface) => row.name,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row: InstallmentInterface) => row.amount,
      sortable: true,
    },
    {
      name: 'Due Date',
      selector: (row: InstallmentInterface) => {
        const date = new Date(row.due_date ?? '');
        return row.due_date ? date.toLocaleDateString() : '';
      },
      sortable: true,
    },
    {
      name: 'Active Status',
      selector: (row: InstallmentInterface) =>
        row.is_active ? 'Active' : 'Inactive',
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="flex justify-end w-full mr-3">
          <FaRegEdit
            className="text-xl text-blue-500 cursor-pointer mr-1"
            onClick={() =>
              row.id &&
              navigate(INSTALLMENT_UPDATE.replace(':id', row.id.toString()))
            }
          />
          <MdDelete
            className="text-xl text-red-500 cursor-pointer"
            onClick={() => row.id !== undefined && handleDelete(row.id)}
          />
        </div>
      ),
      button: true,
      right: true,
    },
  ];

  const handleDelete = async (id: number) => {
    openDialog(
      'Delete Installment',
      'Are you sure you want to delete this installment?',
      async () => {
        try {
          const response = await archiveInstallment(id);
          if (response.success) {
            setInstallments((prevInstallments) =>
              prevInstallments.filter((installment) => installment.id !== id),
            );
            notifySuccess('installment deleted successfully!');
          } else {
            notifyError('Failed to delete installment');
          }
        } catch (error) {
          console.error('Error deleting installment:', error);
          notifyError('Error deleting installment');
        } finally {
          closeDialog();
        }
      },
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="flex justify-between mb-2">
          <h2 className="text-gray-800 text-2xl font-medium">
            Installment List
          </h2>
          <div className="flex justify-between">
            <button
              className="bg-green-500 cursor-pointer text-xs font-medium rounded p-2.5 hover:bg-green-600 text-white"
              onClick={() => navigate(INSTALLMENT_CREATE)}
            >
              Create
            </button>
          </div>
        </div>
        <div>
          <CustomDataTable
            columns={columns}
            data={installments}
            loading={loading}
            totalRows={totalRows}
            currentPage={currentPage}
            perPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerRowsChange}
          />
        </div>
      </div>
      <ConfirmationDialog
        title={dialogTitle}
        message={dialogMessage}
        onConfirm={dialogAction}
        onCancel={closeDialog}
        isOpen={dialogOpen}
      />
    </>
  );
};

export default List;
