import { RouteObject } from 'react-router-dom';
import List from '../../pages/settings/division/List';
import AddUpdate from '../../pages/settings/division/AddUpdate';

export const DIVISION_TITLE = 'Division';
export const DIVISION_LIST = '/division';
export const DIVISION_CREATE = '/division/add';
export const DIVISION_UPDATE = '/division/:id/edit';

const DivisionRoutes: RouteObject[] = [
  {
    path: DIVISION_LIST,
    element: <List />,
  },
  {
    path: DIVISION_CREATE,
    element: <AddUpdate isEdit={false} />,
  },
  {
    path: DIVISION_UPDATE,
    element: <AddUpdate isEdit={true} />,
  },
];

export default DivisionRoutes;
