import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createDivision,
  getDivisionByID,
  updateDivision,
} from '../../../controller';
import { ResponseDivision } from '../../../types/Division';
import { notifySuccess, notifyError } from '../../../components/CustomToast';
import {
  DIVISION_LIST,
  DIVISION_TITLE,
} from '../../../routes/settings/Division';
import axios from 'axios';

const AddUpdate: React.FC<{ isEdit?: boolean }> = ({ isEdit = false }) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<ResponseDivision>({
    id: 0,
    name: '',
    is_active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit && id) {
      const fetchDivision = async () => {
        setLoading(true);
        try {
          const { data, success, message } = await getDivisionByID(Number(id));
          if (success) {
            setFormData(data);
          } else {
            console.error('Failed to fetch division:', message);
          }
        } catch (error) {
          console.error('Error fetching division:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchDivision();
    }
  }, [isEdit, id]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_active' ? value === 'true' : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (isEdit && id) {
        const { success, message } = await updateDivision(formData);
        if (success) {
          notifySuccess('Division Updated');
          navigate(DIVISION_LIST);
        } else {
          notifyError('Division Updation Failed');
          console.error('Error submitting form:', message);
        }
      } else {
        const { success, message } = await createDivision(formData);
        if (success) {
          notifySuccess('Division Created');
          navigate(DIVISION_LIST);
        } else {
          notifyError('Division Creation Failed');
          console.error('Error submitting form:', message);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notifyError(error.response.data.message);
        } else {
          notifyError('Internal Server Error');
        }
      } else {
        notifyError('An unexpected error occurred');
      }
      console.error('Error submitting form:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-65px)]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <>
      <div className="m-5 bg-white rounded p-2">
        <div className="border border-gray-200">
          <div className="flex justify-between bg-[#3F51B5] p-2">
            <h2 className="text-white text-base font-normal px-4">
              {isEdit ? 'Edit' : 'Create'} {DIVISION_TITLE}
            </h2>
          </div>
          <div className="p-5">
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <label className="block">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder=" "
                        className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                        required
                      />
                      <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                        Division Name *
                      </span>
                    </label>
                  </div>
                  <div className="relative w-1/2">
                    <select
                      name="is_active"
                      value={formData.is_active.toString()}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-md p-2 w-full outline-none focus:ring-indigo-500 focus:border-indigo-500 peer"
                      required
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                    <input
                      type="text"
                      className="absolute opacity-0 w-0 h-0 peer"
                      value={
                        formData.is_active.toString() === 'true'
                          ? 'Active'
                          : 'Inactive'
                      }
                      readOnly
                      tabIndex={-1}
                    />
                    <span className="absolute left-2 top-2 text-gray-500 transition-all duration-300 transform -translate-y-3 scale-75 origin-top-left peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:bg-white peer-focus:translate-x-1 peer-not-placeholder-shown:scale-75 peer-not-placeholder-shown:-translate-y-4 peer-not-placeholder-shown:bg-white peer-not-placeholder-shown:translate-x-1">
                      Status *
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                >
                  {isEdit ? 'Update' : 'Create'}
                </button>
                <button
                  type="button"
                  className="bg-red-500 text-white py-2 px-4 ml-4 rounded hover:bg-red-600"
                  onClick={() => navigate(DIVISION_LIST)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdate;
