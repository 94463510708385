import { GetRequest, Response } from '../../types/Comman';
import { ResponseMedium, assignMediums } from '../../types/Medium';
import AxiosInstance from '../../utils/AxiosConfig';
const baseURL = 'medium';

// Medium
export const getMedium = async (body: GetRequest): Promise<Response<any>> => {
  const { page, limit, filter } = body;
  let url = `${baseURL}/get-all-medium?page=${page}&limit=${limit}`;
  if (filter) {
    url += `&column=${filter.column}&operation=${filter.operation}&value=${filter.value}`;
  }
  const response = await AxiosInstance.get(url);
  return response.data;
};

export const getMediumsByDepartment = async (
  departmentId: number,
): Promise<any> => {
  const response = await AxiosInstance.get(
    `${baseURL}/mediumsbydepartment/${departmentId}`,
  );
  return response.data;
};

export const getMediumByStandard = async (
  department_id: number,
  sid_id: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(
    `${baseURL}/mediumsbystandard?department_id=${department_id}&sid_id=${sid_id}`,
  );
  return response.data;
};

export const getSearchMedium = async (
  body: GetRequest,
): Promise<Response<any>> => {
  const { page, limit, search } = body;
  const response = await AxiosInstance.get(
    `${baseURL}/get-all-medium?page=${page}&limit=${limit}&search=${search}`,
  );
  return response.data;
};

export const assignMedium = async (
  body: assignMediums,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/assign-mediums`, body);
  return response.data;
};

export const createMedium = async (
  body: ResponseMedium,
): Promise<Response<any>> => {
  const response = await AxiosInstance.post(`${baseURL}/create-medium`, body);
  return response.data;
};

export const updateMedium = async (
  body: ResponseMedium,
): Promise<Response<any>> => {
  const response = await AxiosInstance.put(
    `${baseURL}/edit-medium/${body.id}`,
    body,
  );
  return response.data;
};

export const archiveMedium = async (
  mediumId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.delete(
    `${baseURL}/delete-medium/${mediumId}`,
  );
  return response.data;
};

export const getMediumByID = async (
  MediumId: number,
): Promise<Response<any>> => {
  const response = await AxiosInstance.get(`${baseURL}/get-medium/${MediumId}`);
  return response.data;
};
